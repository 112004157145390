import type { GtmProject } from 'src/gtmProject/Project.types';
import { useDeleteFile } from 'src/hooks/evoContext/useDeleteFile';
import { useLazyFetchGtmProjectFile } from 'src/hooks/evoContext/useLazyFetchGtmProjectFile';
import { useLazyUploadFile } from 'src/hooks/evoContext/useLazyUploadFile';
import { createFileFromProject } from 'src/hooks/utils';
import { addToFrontOfProjectFiles, removeProjectFile } from 'src/store/evo/evoSlice';
import {
    clearProject,
    overwriteProject,
    setCurrentProjectVersionId,
} from 'src/store/project/projectSlice';
import { useAppDispatch } from 'src/store/store';
import { PROJECT_EXTENSION } from 'src/strings';

export function useProjectManager() {
    const [FetchGtmProjectFileTrigger] = useLazyFetchGtmProjectFile();
    const dispatch = useAppDispatch();
    const [UploadFileTrigger] = useLazyUploadFile();
    const [DeleteFileTrigger] = useDeleteFile();

    async function initializeNewProjectFile(newName: string, description?: string) {
        const newProject = {
            name: newName,
            description,
            history: { undoEntries: [], redoEntries: [] },
            models: [],
        };
        const newProjectFile = createFileFromProject(newProject);

        const resp = await UploadFileTrigger(newProjectFile);
        if ('error' in resp) {
            return Promise.reject(resp.error);
        }

        dispatch(addToFrontOfProjectFiles(newName));
        return resp.data;
    }

    async function deleteProjectFile(projectName: string) {
        const resp = await DeleteFileTrigger(`${projectName}.${PROJECT_EXTENSION}`);
        if ('error' in resp) {
            return Promise.reject(resp.error);
        }

        dispatch(removeProjectFile(projectName));

        return resp.data;
    }

    /**
     * File service does not support renaming files. This is a workaround to renaming a project by creating a project copy and deleting the old project.
     */
    async function renameProjectFile(project: GtmProject, newName: string) {
        const oldProjectName = project.name;
        const newProjectFile = createFileFromProject({
            ...project,
            name: newName,
        });
        const resp = await UploadFileTrigger(newProjectFile);
        if ('error' in resp) {
            return Promise.reject(resp.error);
        }

        try {
            await deleteProjectFile(oldProjectName);
            dispatch(addToFrontOfProjectFiles(newName));
            return resp.data;
        } catch (e) {
            // Rename failed due to error deleting old project after creating a new one. Try deleting the new project
            await deleteProjectFile(newName);
            return Promise.reject(e);
        }
    }

    async function loadProject(projectName: string, versionId?: string, clearSelections = true) {
        const [project, newVersionId] =
            (await FetchGtmProjectFileTrigger(projectName, versionId)) ?? [];
        if (project && newVersionId) {
            if (clearSelections) {
                dispatch(clearProject());
            }
            dispatch(setCurrentProjectVersionId(newVersionId));
            dispatch(overwriteProject({ project }));
        }
        return project;
    }

    return {
        initializeNewProjectFile,
        deleteProjectFile,
        renameProjectFile,
        loadProject,
    };
}
