import { createSelector } from '@reduxjs/toolkit';

import type {
    GtmMeshDetectorAction,
    GtmMeshDetectionData,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { selectCurrentModelSelectedObject } from 'src/store/project/selectors';

import type { RootState } from '../store';
import type { IssuesState, IssuesMap, IssueInfo, IssuesObjectMap } from './issuesSlice.types';

type SelectorTypeIssuesObjectMap = (state: RootState) => IssuesObjectMap | undefined;
type SelectorTypeIssuesMap = (state: RootState) => IssuesMap | undefined;
type SelectorTypeIssueInfo = (state: RootState) => IssueInfo | undefined;
type SelectorTypeDetectionData = (state: RootState) => GtmMeshDetectionData | undefined;
type SelectorTypeBoolean = (state: RootState) => boolean;

export const initialState: IssuesState = {
    issuesMap: {},
};

const issuesState = (state: RootState): IssuesState => state.issues ?? initialState;

export const issuesMapForAllObjects: SelectorTypeIssuesObjectMap = createSelector(
    issuesState,
    (issuesStateRoot) => issuesStateRoot.issuesMap,
);

export const issuesMapForObject = (objectId: string): SelectorTypeIssuesMap =>
    createSelector(issuesMapForAllObjects, (issuesObjectMap) => issuesObjectMap?.[objectId]);

export const issueForObjectAndAction = (
    objectId: string,
    action: GtmMeshDetectorAction,
): SelectorTypeIssueInfo =>
    createSelector(issuesMapForObject(objectId), (issuesMap) => issuesMap?.[action]);

export const issueDataForObjectAndAction = (
    objectId: string,
    action: GtmMeshDetectorAction,
): SelectorTypeDetectionData =>
    createSelector(issueForObjectAndAction(objectId, action), (issue) => issue?.data);

export const anyIssueIsStillLoading = (objectId: string): SelectorTypeBoolean =>
    createSelector(issuesMapForObject(objectId), (issuesMap) =>
        Object.values(issuesMap ?? {}).some((issue) => issue.isLoading),
    );

export const selectIssueDataForCurrentModelSelectedObject: SelectorTypeIssuesMap = createSelector(
    issuesMapForAllObjects,
    selectCurrentModelSelectedObject,
    (issuesObjectMap, currentModelSelectedObject) => {
        if (issuesObjectMap && currentModelSelectedObject) {
            return issuesObjectMap[currentModelSelectedObject.id];
        }
        return undefined;
    },
);

export const selectDoesCurrentModelSelectedObjectHaveIssues: SelectorTypeBoolean = createSelector(
    selectIssueDataForCurrentModelSelectedObject,
    (issuesMap) => Object.values(issuesMap ?? {}).some((issue) => (issue.data?.length ?? 0) > 0),
);

export const selectDoesCurrentModelObjectHaveIssues = (objectId: string): SelectorTypeBoolean =>
    createSelector(issuesMapForObject(objectId), (issueMap) =>
        Object.values(issueMap ?? {}).some((issue) => (issue.data?.length ?? 0) > 0),
    );
