import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface HelperAccordionProps {
    title: string;
    detailedExplanation: string;
    sx?: SxProps<Theme>;
}

export const HelperAccordion = ({ title, detailedExplanation, sx }: HelperAccordionProps) => {
    const theme = useTheme();
    const applyTrace = useTrace('helper-accordion');

    return (
        <Box sx={sx}>
            <Accordion
                disableGutters
                elevation={1}
                sx={{ bgcolor: theme.palette.info.light }}
                TransitionProps={{ unmountOnExit: true }}
            >
                <AccordionSummary
                    sx={{
                        '& .MuiAccordionSummary-content': { m: 0 }, // otherwise too tall
                        minHeight: 30, // otherwise too tall
                        pl: 1,
                        pr: 1,
                    }}
                    automation-id={applyTrace('summary')}
                    expandIcon={<ExpandMore sx={{ fill: theme.palette.info.contrastText }} />}
                >
                    <Stack direction="row" alignItems="center" alignContent="center">
                        <IconButton size="small" sx={{ mr: theme.spacing(1), p: 0 }}>
                            <InfoIcon
                                fontSize="small"
                                sx={{ fill: theme.palette.info.contrastText }}
                            />
                        </IconButton>
                        <Typography variant="caption" color={theme.palette.primary.contrastText}>
                            {title}
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="caption" color={theme.palette.primary.contrastText}>
                        {detailedExplanation}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
