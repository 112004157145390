import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';

import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { MESH_SCHEMA } from 'src/constants';
import { useDeleteFile } from 'src/hooks/evoContext/useDeleteFile';
import { useLazyUploadFile } from 'src/hooks/evoContext/useLazyUploadFile';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { addUserTriangleMesh } from 'src/store/evo/evoSlice';
import { useAppDispatch } from 'src/store/store';
import type { GeoscienceObject } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';
import { fileNameExtensionRemover } from 'src/utils/fileManagement';

export function useObjToTriangleMeshUploader() {
    const [UploadFileTrigger] = useLazyUploadFile();
    const [DeleteFileTrigger] = useDeleteFile();
    const { addMessage } = useMessagesContext();
    const dispatch = useAppDispatch();
    const { transformMeshData } = useUploadMeshDataTransformer();

    // obj to triangle mesh upload workflow is as follows:
    // 1. Upload the obj file to the File service.
    // 2. Call `UploadMeshData` action of the compute task, point it to the uploaded file. It transforms and uploads the triangle mesh to GOOSE.
    // 3. Delete the file from the File service.
    async function uploadObjAsTriangleMesh(objFile: File) {
        return UploadFileTrigger(objFile)
            .then(async ({ data: uploadData }) => {
                const name = fileNameExtensionRemover(uploadData!.name);
                const result = await transformMeshData(uploadData!.file_id, name);
                const newObject = decorateNewObject(result.created[0], name, MESH_SCHEMA, true);
                dispatch(addUserTriangleMesh(newObject));
                return [uploadData, newObject] as [typeof uploadData, GeoscienceObject];
            })
            .then(async ([uploadData, newObject]) => {
                await DeleteFileTrigger(
                    encodeURIComponent(`${uploadData!.path}${uploadData!.name}`),
                );
                return newObject;
            })
            .catch((error) => {
                addMessage({
                    message: `Failed to upload file ${objFile.name}.`,
                    type: NotificationType.ERROR,
                });
                trackError(`Error: ${error} uploading file "${objFile.name}"`);
                return Promise.reject(error);
            });
    }

    return { uploadObjAsTriangleMesh };
}

function useUploadMeshDataTransformer() {
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const gooseContext = useGooseContext();

    return {
        transformMeshData: async (fileId: string, name: string) =>
            GtmMeshTransformationTrigger(
                formGtmMeshTransformationBody(
                    gooseContext!,
                    GtmMeshTransformationAction.UploadMeshData,
                    [],
                    {
                        file_id: fileId,
                        name,
                    },
                ),
            ).unwrap(),
    };
}
