import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { detectorActionToDefectString } from 'src/utils/typeTransformations';

import {
    generateTriangleDefects,
    generatePointDefects,
    generateHoleDefects,
    generateEdgeDefects,
} from './defectsGenerators';
import type { DefectsSnapshot, DefectsState } from './types';

const DEFECTS_FACTORY = {
    [GtmMeshDetectorAction.DetectDegenerateTris]: generateTriangleDefects,
    [GtmMeshDetectorAction.DetectDuplicatePoints]: generatePointDefects,
    [GtmMeshDetectorAction.DetectDuplicateTris]: generateTriangleDefects,
    // TODO: [GtmMeshDetectorAction.DetectFins]: ,
    [GtmMeshDetectorAction.DetectHoles]: generateHoleDefects,
    [GtmMeshDetectorAction.DetectSelfIntersections]: generateTriangleDefects,
    [GtmMeshDetectorAction.DetectNonPartitioningSurfaces]: generateTriangleDefects,
    [GtmMeshDetectorAction.DetectNonManifoldEdges]: generateEdgeDefects,
    [GtmMeshDetectorAction.DetectNonManifoldVertices]: generatePointDefects,
    [GtmMeshDetectorAction.DetectInconsistentlyOrientedTris]: generateTriangleDefects,
};

export function generateDefectsSnapshotState(
    type: GtmMeshDetectorAction,
    state: DefectsState,
): DefectsSnapshot {
    const snapshotGenerator = DEFECTS_FACTORY[type as keyof typeof DEFECTS_FACTORY];
    if (!snapshotGenerator) {
        throw new Error(`Unknown defect type: ${type}`);
    }
    const defectName = detectorActionToDefectString(type);
    return snapshotGenerator(defectName, state) as DefectsSnapshot;
}
