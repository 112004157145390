import { useEffect } from 'react';

import type { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { useDefectsVisualizationManager } from 'src/hooks/defects';
import type { IssueInfo } from 'src/store/issues/issuesSlice.types';

export const IssueRenderer = ({
    objectId,
    action,
    info,
}: {
    objectId: string;
    action: GtmMeshDetectorAction;
    info: IssueInfo;
}) => {
    const { refreshIssues, removeIssuesForObject } = useDefectsVisualizationManager();

    useEffect(() => {
        if (!info.isLoading && !info.isError) {
            if (info.data) refreshIssues(objectId, action, info.data);
            else removeIssuesForObject(objectId, action);
        }
        return () => {
            removeIssuesForObject(objectId, action);
        };
    }, [info]);

    return null;
};
