import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_MODEL_SETTINGS } from 'src/constants';
import type { GtmModel } from 'src/gtmProject';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { skipHistoryEntry, useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import {
    selectCurrentProjectData,
    selectCurrentProjectModelNames,
    selectCurrentProjectModels,
    selectIsCurrentProjectEmpty,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { DEFAULT_LIST_MAX_HEIGHT_PX } from 'src/styles';
import { getNextSequentialName } from 'src/utils/stringHelpers';
import { ModelListEntry } from 'src/visualization/ProjectPanel/components/ModelsPanel/ModelListEntry';
import { ModelsLoading } from 'src/visualization/ProjectPanel/components/ModelsPanel/ModelsLoading';
import {
    getAddModelDescription,
    INVALID_NEW_MODEL_NAME_MESSAGE,
    MODELS_LABEL,
    NEW_MODEL_NAME,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function ModelsPanel() {
    const applyTrace = useTrace('models-panel');
    const theme = useTheme();
    const [newModelName, setNewModelName] = useState('');
    const [isAddingNewModel, setIsAddingNewModel] = useState(false);
    const models = useAppSelector(selectCurrentProjectModels);
    const currentProject = useAppSelector(selectCurrentProjectData);
    const currentProjectModelNames = useAppSelector(selectCurrentProjectModelNames);
    const isNewNameInvalid = useMemo(
        () => currentProjectModelNames.includes(newModelName),
        [currentProjectModelNames, newModelName],
    );
    const [shouldDisableAddModelButton, setShouldDisableAddModelButton] = useState(false);
    const newModelInputRef = useRef<HTMLLIElement>(null);
    const isCurrentProjectEmpty = useAppSelector(selectIsCurrentProjectEmpty);
    const { syncProject } = useProjectSynchronizer();
    const { addNewModelAndSetAsCurrent } = useConglomerateActionManager();
    const shouldDisableAddConfirm = isNewNameInvalid || newModelName.trim() === '';

    useEffect(() => {
        const addNewModelIfNoModelsInProject = () => {
            const newModel: GtmModel = {
                id: uuidv4(),
                name: NEW_MODEL_NAME,
                inputObjects: [],
                settings: DEFAULT_MODEL_SETTINGS,
            };
            addNewModelAndSetAsCurrent(newModel);
            syncProject(skipHistoryEntry);
            setIsAddingNewModel(false);
            setNewModelName('');
        };

        if (!isCurrentProjectEmpty && models.length === 0) {
            addNewModelIfNoModelsInProject();
        }
    }, [currentProject, models]);

    useEffect(() => {
        if (isAddingNewModel) {
            newModelInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [isAddingNewModel]);

    const handleAddModel = () => {
        setIsAddingNewModel(true);
        setNewModelName(
            getNextSequentialName(
                NEW_MODEL_NAME,
                models.map((model) => model.name ?? null).filter(Boolean) as string[],
            ),
        );
    };

    const handleNewModelNameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewModelName(event.target.value);
    };

    const handleConfirmNewModel = () => {
        const newModel: GtmModel = {
            id: uuidv4(),
            name: newModelName,
            inputObjects: [],
            settings: DEFAULT_MODEL_SETTINGS,
        };

        addNewModelAndSetAsCurrent(newModel);
        syncProject({ description: getAddModelDescription(newModelName) });
        setIsAddingNewModel(false);
        setNewModelName('');
    };

    const handleCancelNewModel = () => {
        setIsAddingNewModel(false);
        setNewModelName('');
    };

    const handleNewModelNameOnKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !shouldDisableAddConfirm) {
            handleConfirmNewModel();
        } else if (event.key === 'Escape') {
            handleCancelNewModel();
        }
    };

    if (models.length === 0) {
        return <ModelsLoading />;
    }

    return (
        <>
            <Box
                automation-id={applyTrace()}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: theme.spacing(2.5, 2),
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textTransform: 'uppercase' }}
                >
                    {MODELS_LABEL}
                </Typography>
                <IconButton
                    automation-id={applyTrace('add-model-button')}
                    disabled={shouldDisableAddModelButton}
                    size="small"
                    onClick={handleAddModel}
                    color="primary"
                >
                    <AddIcon />
                </IconButton>
            </Box>
            <Box p={theme.spacing(0, 2, 2, 2)}>
                <Box
                    sx={{
                        maxHeight: DEFAULT_LIST_MAX_HEIGHT_PX,
                        overflowY: 'auto',
                        borderRadius: 0.5,
                        border: 1,
                        borderColor: 'divider',
                    }}
                >
                    <List dense disablePadding>
                        {models.map((model, index) => (
                            <ModelListEntry
                                index={index}
                                key={model.id}
                                model={model}
                                disableMenu={isAddingNewModel}
                                setShouldDisableAddModelButton={setShouldDisableAddModelButton}
                                lastItem={index === models.length - 1}
                            />
                        ))}
                        {isAddingNewModel ? (
                            <ListItem ref={newModelInputRef} divider disableGutters disablePadding>
                                <Stack
                                    sx={{
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                    }}
                                    direction="row"
                                    p={theme.spacing(0.5, 1, 1, 2)}
                                >
                                    <TextField
                                        automation-id={applyTrace('add-model-input')}
                                        size="small"
                                        variant="standard"
                                        value={newModelName}
                                        onChange={handleNewModelNameOnChange}
                                        onKeyUp={handleNewModelNameOnKeyUp}
                                        InputProps={{ sx: { fontSize: '12px' } }}
                                        error={isNewNameInvalid}
                                        helperText={
                                            isNewNameInvalid ? INVALID_NEW_MODEL_NAME_MESSAGE : null
                                        }
                                    />
                                    <Stack direction="row">
                                        <IconButton
                                            automation-id={applyTrace('confirm-model-button')}
                                            sx={{ paddingRight: theme.spacing(1) }}
                                            size="small"
                                            disabled={shouldDisableAddConfirm}
                                            onClick={handleConfirmNewModel}
                                        >
                                            <CheckIcon
                                                color={
                                                    shouldDisableAddConfirm ? 'disabled' : 'primary'
                                                }
                                            />
                                        </IconButton>
                                        <IconButton
                                            automation-id={applyTrace('cancel-new-model-button')}
                                            sx={{ padding: 0 }}
                                            size="small"
                                            onClick={handleCancelNewModel}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </ListItem>
                        ) : null}
                    </List>
                </Box>
            </Box>
        </>
    );
}
