import { createSelector } from '@reduxjs/toolkit';

import type { GeoscienceObject } from 'src/types/core.types';

import type { RootState } from '../store';
import type { EvoState, WorkspaceState } from './evoSlice.types';

type SelectorTypeWorkspace = (state: RootState) => WorkspaceState;
type SelectorTypeString = (state: RootState) => string | undefined;
type SelectorTypeStringList = (state: RootState) => string[] | undefined;
type SelectorTypeGeoscienceObjects = (state: RootState) => GeoscienceObject[] | undefined;

export const initialEvoState: EvoState = {
    workspace: {
        name: undefined,
        projectFiles: undefined,
        userTriangleMeshes: undefined,
    },
};

const evoState = (state: RootState): EvoState => state.evo ?? initialEvoState;

export const selectWorkspace: SelectorTypeWorkspace = createSelector(
    evoState,
    (rootEvoState) => rootEvoState.workspace,
);

export const selectWorkspaceName: SelectorTypeString = createSelector(
    selectWorkspace,
    (workspace) => workspace.name,
);

export const selectGtmProjects: SelectorTypeStringList = createSelector(
    selectWorkspace,
    (workspace) => workspace.projectFiles,
);

export const selectUserTriangleMeshes: SelectorTypeGeoscienceObjects = createSelector(
    selectWorkspace,
    (workspace) => workspace.userTriangleMeshes,
);
