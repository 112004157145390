import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import type { MouseEvent } from 'react';
import { useState } from 'react';

import {
    GtmMeshDetectorAction,
    GtmMeshFillMode,
    GtmMeshTransformationAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { FillAndRefineHolesIcon } from 'src/assets/FillAndRefineHolesIcon';
import { FillHolesIcon } from 'src/assets/FillHoleIcon';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
    TransformationStatus,
    useTransformationManager,
} from 'src/hooks/transformation/useTransformationManager';
import { issueDataForObjectAndAction } from 'src/store/issues/selectors';
import { selectCurrentModelSelectedObject } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { assert } from 'src/utils/gtmAssert';
import {
    FILL_AND_REFINE_INFO,
    FILL_AND_REFINE_LABEL,
    FILL_INFO,
    FILL_LABEL,
    FILLING_HOLES_FAILURE_MESSAGE,
    FILLING_HOLES_MESSAGE,
    FILLING_HOLES_SUCCESS_MESSAGE,
    getFillAllHolesLabel,
} from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/TransformationAction.constants';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

const titlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, FILLING_HOLES_MESSAGE],
    [TransformationStatus.Uploading, FILLING_HOLES_MESSAGE],
    [TransformationStatus.Complete, FILLING_HOLES_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, FILLING_HOLES_FAILURE_MESSAGE],
]);
const percentagesByStatus = new Map<TransformationStatus, number>([
    [TransformationStatus.Transforming, 0],
    [TransformationStatus.Uploading, 60],
    [TransformationStatus.Complete, 100],
    [TransformationStatus.Failed, 100],
]);

// Need to specify this explicitly since the menu has a fixed position and is not a part of the issues tab hierarchy
const MENU_PAPER_WIDTH_PX = '243px';

export const FillAllHoles = () => {
    const applyTrace = useTrace('fill-all-holes');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { executeTransformation, transformationStatus } = useTransformationManager();
    const selectedObject = useAppSelector(selectCurrentModelSelectedObject);
    assert(selectedObject !== undefined, 'Filling holes does not know to which object it applies.');
    const selectedObjectHoles = useAppSelector(
        issueDataForObjectAndAction(selectedObject!.id, GtmMeshDetectorAction.DetectHoles),
    );
    const { resetVolumesIfObjectIsAggregate } = useVolumesManager();

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleTransformation = (fillMode: GtmMeshFillMode) => {
        if (!selectedObjectHoles || !selectedObject) {
            return;
        }

        const params = { fillMode, edges: selectedObjectHoles.map(({ edges }) => edges[0]) };
        executeTransformation(
            GtmMeshTransformationAction.FillHoles,
            ShouldRenderUpdatedObjects.Yes,
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [selectedObject],
            params,
            {
                handleAdditionalSideEffects: () => {
                    resetVolumesIfObjectIsAggregate(selectedObject);
                },
            },
        );
    };

    const handleFillClick = () => {
        // Default fill is "fill and refine" mode as per design
        handleTransformation(GtmMeshFillMode.FillAndRefine);
        handleCloseMenu();
    };

    const handleFillAndRefineClick = () => {
        // Refined fill is "fill, refine and fair" mode as per design
        handleTransformation(GtmMeshFillMode.FillRefineAndFair);
        handleCloseMenu();
    };

    return (
        <>
            <Button
                automation-id={applyTrace('dropdown-button')}
                size="small"
                sx={{ width: '100%' }}
                variant="contained"
                onClick={handleOpenMenu}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {getFillAllHolesLabel(selectedObjectHoles?.length ?? 0)}
            </Button>
            <Menu
                slotProps={{
                    paper: {
                        sx: (theme) => ({
                            width: MENU_PAPER_WIDTH_PX,
                            marginTop: theme.spacing(2),
                        }),
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    automation-id={applyTrace('fill-button')}
                    sx={{ justifyContent: 'space-between' }}
                    onClick={handleFillClick}
                >
                    <Stack direction="row">
                        <FillHolesIcon
                            sx={(theme) => ({ fontSize: '20px', marginRight: theme.spacing(2) })}
                        />
                        {FILL_LABEL}
                    </Stack>
                    <Tooltip title={FILL_INFO}>
                        <IconButton size="small">
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </MenuItem>
                <MenuItem
                    automation-id={applyTrace('fill-and-refine-button')}
                    sx={{ justifyContent: 'space-between' }}
                    onClick={handleFillAndRefineClick}
                >
                    <Stack direction="row">
                        <FillAndRefineHolesIcon
                            sx={(theme) => ({ fontSize: '20px', marginRight: theme.spacing(2) })}
                        />
                        {FILL_AND_REFINE_LABEL}
                    </Stack>
                    <Tooltip title={FILL_AND_REFINE_INFO}>
                        <IconButton size="small">
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </MenuItem>
            </Menu>
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationTitles={titlesByStatus}
                transformationPercentages={percentagesByStatus}
            />
        </>
    );
};
