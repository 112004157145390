import { WDSThemeProvider } from '@local/web-design-system-2';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';

import { defaultAnalyticalModelSettings } from 'src/apiClients/gtmCompute/gtmComputeApi';
import type { GtmRemeshParams } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { HelperAccordion } from 'src/components/HelperAccordion';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import type { GtmRemeshSettings } from 'src/gtmProject';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import type { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { updateModelAtIndexForCurrentProject } from 'src/store/project/projectSlice';
import { selectCurrentAnalyticalModelSettings } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    analyticalModelSettingsFromRemeshSettings,
    initialRemeshSettingsState,
    selectAllRemeshSettings,
    selectPatchAngleTolerance,
    selectPatchAngleToleranceValid,
    setAllRemeshSettings,
    setMaxChordalError,
    setMaxChordalErrorValid,
    setPatchAngleTolerance,
    setPatchAngleToleranceValid,
    setShapeQualityWeight,
    setShapeQualityWeightValid,
    setTargetElementSize,
    setTargetElementSizeValid,
} from 'src/store/ui/remeshSettings';
import { selectedSceneObjects } from 'src/store/visualization/selectors';
import { CANCEL_LABEL, DEFAULT_LABEL } from 'src/strings';
import { DEFAULT_PANEL_WIDTH, DEFAULT_SETTING_FIELD_WIDTH_PX } from 'src/styles';
import { stringRepresentsFiniteNumber } from 'src/utils/math';
import {
    APPLY_LABEL,
    HELP_REMESHING_INFO,
    HELP_WHAT_IS_REMESHING,
    MAX_CHORDAL_ERROR,
    MAX_CHORDAL_ERROR_INFO,
    PATCH_ANGLE_TOLERANCE,
    PATCH_ANGLE_TOLERANCE_INFO,
    REMESH_LABEL,
    SHAPE_QUALITY_WEIGHT,
    SHAPE_QUALITY_WEIGHT_INFO,
    TARGET_H,
    TARGET_H_INFO,
} from 'src/visualization/Toolbar/RemeshSettingsDialog.constants';

import { useStyles } from './RemeshSettingsDialog.styles';

export function RemeshSettingsDialog({
    modelIndex,
    anchorEl,
    executeTransformation,
    onClose,
}: {
    modelIndex: number;
    anchorEl: HTMLElement | null;
    executeTransformation: ReturnType<typeof useTransformationManager>['executeTransformation'];
    onClose: () => void;
}) {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const dispatch = useAppDispatch();
    const selectedObject = Object.values(useAppSelector(selectedSceneObjects))[0];
    const currentAnalyticalModelSettings =
        useAppSelector(selectCurrentAnalyticalModelSettings) ?? defaultAnalyticalModelSettings;
    const remeshSettingsUiState = useAppSelector(selectAllRemeshSettings);
    const { resetVolumesIfObjectIsAggregate } = useVolumesManager();

    const open = Boolean(anchorEl);
    const id = open ? 'remesh-popover' : undefined;

    if (anchorEl === null) return null;

    const transform = (remeshSettings: GtmRemeshSettings) => {
        const params: GtmRemeshParams = {
            patchAngleTolerance: remeshSettings.patchAngleTolerance,
            maxChordalError: remeshSettings.maxChordalError,
            shapeQualityWeight: remeshSettings.shapeQualityWeight,
            targetH: remeshSettings.targetH,
            isClosed: false, // TODO: set from object once we have that information
            lineProximityDetection: false, // TODO: consider adding it to UI
            strainTolerance: 0.3, // CM2 default
            initialCleanup: true,
            optimize: true,
        };

        executeTransformation(
            GtmMeshTransformationAction.Remesh,
            ShouldRenderUpdatedObjects.Yes,
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [selectedObject],
            params,
            {
                handleAdditionalSideEffects: () => {
                    resetVolumesIfObjectIsAggregate(selectedObject);
                },
            },
        );
    };

    const onRemesh = () => {
        if (!selectedObject) {
            return;
        }

        const updatedAnalyticalModelSettings = analyticalModelSettingsFromRemeshSettings(
            currentAnalyticalModelSettings,
            remeshSettingsUiState,
        );
        dispatch(
            updateModelAtIndexForCurrentProject([
                { analyticalModelSettings: updatedAnalyticalModelSettings },
                modelIndex,
            ]),
        );

        const { remeshSettings } = updatedAnalyticalModelSettings;
        transform(remeshSettings);
        onClose();
    };

    const DIALOG_WIDTH = DEFAULT_PANEL_WIDTH;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            anchorReference="anchorPosition"
            anchorPosition={{
                top: anchorEl ? anchorEl.getBoundingClientRect().bottom + 4 : 0,
                left: anchorEl ? anchorEl.getBoundingClientRect().right - DIALOG_WIDTH : 0,
            }}
        >
            <Box sx={{ width: DIALOG_WIDTH }}>
                <WDSThemeProvider themeMode={appTheme}>
                    <Paper elevation={16}>
                        <DialogTitle>{REMESH_LABEL}</DialogTitle>
                        <Divider />
                        <HelperAccordion
                            sx={{ p: 2, pb: 0 }}
                            title={HELP_WHAT_IS_REMESHING}
                            detailedExplanation={HELP_REMESHING_INFO}
                        />
                        <RawRemeshSettings />
                        <Divider />
                        <ResetCancelApply onClose={onClose} onRemesh={onRemesh} />
                    </Paper>
                </WDSThemeProvider>
            </Box>
        </Popover>
    );
}

function ResetCancelApply({ onClose, onRemesh }: { onClose: () => void; onRemesh: () => void }) {
    const applyTrace = useTrace('remesh-settings');
    const settingsUiState = useAppSelector(selectAllRemeshSettings);
    const dispatch = useAppDispatch();
    const onReset = () => {
        dispatch(setAllRemeshSettings(initialRemeshSettingsState));
    };
    const onCancel = () => {
        onClose();
    };

    const allValid =
        settingsUiState.maxChordalErrorValid &&
        settingsUiState.patchAngleToleranceValid &&
        settingsUiState.shapeQualityWeightValid &&
        settingsUiState.targetElementSizeValid;
    const isDisabled = !allValid;

    return (
        <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
                automation-id={applyTrace('reset-button')}
                variant="text"
                size="medium"
                color="secondary"
                onClick={onReset}
                sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
            >
                {DEFAULT_LABEL}
            </Button>
            <Button
                automation-id={applyTrace('cancel-button')}
                variant="text"
                size="medium"
                color="secondary"
                onClick={onCancel}
            >
                {CANCEL_LABEL}
            </Button>
            <Button
                automation-id={applyTrace('apply-button')}
                variant="text"
                size="medium"
                color="primary"
                onClick={onRemesh}
                disabled={isDisabled}
            >
                {APPLY_LABEL}
            </Button>
        </DialogActions>
    );
}

function RawRemeshSettings() {
    const { classes } = useStyles();

    return (
        <Stack direction="column" className={classes.settingsSection}>
            <PatchAngleToleranceInput />
            <MaxChordalErrorInput />
            <TargetElementSizeInput />
            <ShapeQualityWeightInput />
        </Stack>
    );
}

function PatchAngleToleranceInput() {
    const applyTrace = useTrace('patch-angle');
    const { classes } = useStyles();
    const patchAngleTolerance = useAppSelector(selectPatchAngleTolerance);
    const inputIsValid = useAppSelector(selectPatchAngleToleranceValid);
    const dispatch = useAppDispatch();

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setPatchAngleTolerance(event.target.value));
        let isValid = stringRepresentsFiniteNumber(event.target.value);
        if (isValid) {
            const val = Number(event.target.value);
            isValid = val > 0 && val <= 45;
        }
        dispatch(setPatchAngleToleranceValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {PATCH_ANGLE_TOLERANCE}
            </Typography>
            <TextField
                automation-id={applyTrace('input-box')}
                value={patchAngleTolerance}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
            />
            <Tooltip title={PATCH_ANGLE_TOLERANCE_INFO}>
                <IconButton size="small" className={classes.infoButton}>
                    <InfoOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function MaxChordalErrorInput() {
    const applyTrace = useTrace('chordal-error');
    const { classes } = useStyles();
    const maxChordalError = useAppSelector((state) => state.remeshSettings.maxChordalError);
    const inputIsValid = useAppSelector((state) => state.remeshSettings.maxChordalErrorValid);
    const dispatch = useAppDispatch();

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setMaxChordalError(event.target.value));
        const isValid = stringRepresentsFiniteNumber(event.target.value);
        dispatch(setMaxChordalErrorValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {MAX_CHORDAL_ERROR}
            </Typography>
            <TextField
                automation-id={applyTrace('input-box')}
                value={maxChordalError}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
            />
            <Tooltip title={MAX_CHORDAL_ERROR_INFO}>
                <IconButton size="small" className={classes.infoButton}>
                    <InfoOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function TargetElementSizeInput() {
    const applyTrace = useTrace('target-size');
    const { classes } = useStyles();
    const targetElementSize = useAppSelector((state) => state.remeshSettings.targetElementSize);
    const inputIsValid = useAppSelector((state) => state.remeshSettings.targetElementSizeValid);
    const dispatch = useAppDispatch();

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setTargetElementSize(event.target.value));
        let isValid = stringRepresentsFiniteNumber(event.target.value);
        if (isValid) {
            const val = Number(event.target.value);
            isValid = val >= 0;
        }
        dispatch(setTargetElementSizeValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {TARGET_H}
            </Typography>
            <TextField
                automation-id={applyTrace('input-box')}
                value={targetElementSize}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
            />
            <Tooltip title={TARGET_H_INFO}>
                <IconButton size="small" className={classes.infoButton}>
                    <InfoOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function ShapeQualityWeightInput() {
    const applyTrace = useTrace('quality-weight');
    const { classes } = useStyles();
    const shapeQualityWeight = useAppSelector((state) => state.remeshSettings.shapeQualityWeight);
    const inputIsValid = useAppSelector((state) => state.remeshSettings.shapeQualityWeightValid);
    const dispatch = useAppDispatch();

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setShapeQualityWeight(event.target.value));
        let isValid = stringRepresentsFiniteNumber(event.target.value);
        if (isValid) {
            const val = Number(event.target.value);
            isValid = val >= 0 && val <= 1;
        }
        dispatch(setShapeQualityWeightValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {SHAPE_QUALITY_WEIGHT}
            </Typography>
            <TextField
                automation-id={applyTrace('input-box')}
                value={shapeQualityWeight}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
            />
            <Tooltip title={SHAPE_QUALITY_WEIGHT_INFO} className={classes.infoButton}>
                <IconButton size="small">
                    <InfoOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}
