import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import type { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'src/store/store';
import {
    selectWorkspaceSearchTerm,
    setIsSearchingWorkspaceObjects,
    setWorkspaceSearchTerm,
} from 'src/store/ui/projectPanel';
import { WORKSPACE_SEARCH_PLACEHOLDER } from 'src/visualization/ProjectPanel/components/ObjectsPanel/ObjectsPanel.constants';

export function WorkspaceSearchInput() {
    const applyTrace = useTrace('workspace-search-input');
    const dispatch = useAppDispatch();
    const workspaceSearchTerm = useSelector(selectWorkspaceSearchTerm);

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setWorkspaceSearchTerm(event.target.value));
    };

    const handleCloseSearch = () => {
        dispatch(setWorkspaceSearchTerm(''));
        dispatch(setIsSearchingWorkspaceObjects(false));
    };

    return (
        <Stack direction="row" sx={(theme) => ({ padding: theme.spacing(0.5, 1, 0.5, 2) })}>
            <TextField
                automation-id={applyTrace()}
                placeholder={WORKSPACE_SEARCH_PLACEHOLDER}
                size="small"
                value={workspaceSearchTerm}
                onChange={handleSearchInputChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <IconButton onClick={handleCloseSearch}>
                <CloseIcon />
            </IconButton>
        </Stack>
    );
}
