import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'src/store/store';
import type { ProjectPanelState } from 'src/store/ui/projectPanel/projectPanel.types';
import {
    BoundaryCreationState,
    isCreatingBoundary,
    ObjectsTabSelection,
} from 'src/store/ui/projectPanel/projectPanel.types';

export const initialProjectPanelState: ProjectPanelState = {
    objectsPanelTabSelection: ObjectsTabSelection.ModelObjects,
    isSearchingWorkspace: false,
    workspaceSearchTerm: '',
    selectedWorkspaceObjectIds: [],
    shouldMinimizeProjectPanel: false,
    shouldOpenNoProjectDialog: false,
    shouldShowProjectPanel: true,
    openUploadObjectsDialog: false,
    boundaryCreationState: BoundaryCreationState.None,
};

type SelectorTypeString = (state: RootState) => string;
type SelectorTypeBoolean = (state: RootState) => boolean;
type SelectorTypeObjectsTabSelection = (state: RootState) => ObjectsTabSelection;
type SelectorTypeStringArray = (state: RootState) => string[];
type SelectorBoundaryCreationState = (state: RootState) => BoundaryCreationState;

const projectPanelState = (state: RootState): ProjectPanelState => state.projectPanel;
export const selectObjectsPanelTabSelection: SelectorTypeObjectsTabSelection = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.objectsPanelTabSelection,
);
export const selectIsSearchingWorkspaceObjects: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.isSearchingWorkspace,
);
export const selectWorkspaceSearchTerm: SelectorTypeString = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.workspaceSearchTerm,
);
export const selectSelectedWorkspaceObjectIds: SelectorTypeStringArray = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.selectedWorkspaceObjectIds,
);
export const selectShouldMinimizeProjectPanel: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldMinimizeProjectPanel,
);
export const selectShouldOpenNoProjectDialog: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldOpenNoProjectDialog,
);
export const selectShouldShowProjectPanel: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldShowProjectPanel,
);
export const selectIsCreatingBoundary: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => isCreatingBoundary(projectPanelStateRoot.boundaryCreationState),
);
export const selectOpenUploadObjectsDialog: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.openUploadObjectsDialog,
);
export const selectBoundaryCreationState: SelectorBoundaryCreationState = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.boundaryCreationState,
);
