import { createSelector } from '@reduxjs/toolkit';

import { defaultAnalyticalModelSettings } from 'src/apiClients/gtmCompute/gtmComputeApi';
import type { GtmAnalyticalModelSettings } from 'src/gtmProject';
import type { RootState } from 'src/store/store';
import type { DetectionSettingsState } from 'src/store/ui/detectionSettings/detectionSettings.types';
import { numberAsMaybeExponentialString, stringAsFiniteNumber } from 'src/utils/math';

export function detectionSettingsFromAnalyticalModelSettings(
    analyticalModelSettings: GtmAnalyticalModelSettings,
): DetectionSettingsState {
    return {
        degenerateTriangleSettings: {
            needleThresholdRatio: numberAsMaybeExponentialString(
                analyticalModelSettings.degenerateTriangleSettings.needleThresholdRatio,
            ),
            needleThresholdRatioValid: true,
            capMinAngleDegrees: numberAsMaybeExponentialString(
                analyticalModelSettings.degenerateTriangleSettings.capMinAngleDegrees,
            ),
            capMinAngleDegreesValid: true,
            needleCollapseLength: numberAsMaybeExponentialString(
                analyticalModelSettings.degenerateTriangleSettings.needleCollapseLength,
            ),
            needleCollapseLengthValid: true,
        },
        holeSettings: {
            holeSizeRatioTolerance: numberAsMaybeExponentialString(
                analyticalModelSettings.holeSettings.holeSizeRatioTolerance,
            ),
            holeSizeRatioToleranceValid: true,
        },
        selfIntersectionSettings: {
            tolerance: numberAsMaybeExponentialString(
                analyticalModelSettings.selfIntersectionSettings.tolerance,
            ),
            toleranceValid: true,
        },
    };
}

export function analyticalModelSettingsFromDetectionSettings(
    existingAnalyticalModelSettings: GtmAnalyticalModelSettings,
    detectionSettings: DetectionSettingsState,
): GtmAnalyticalModelSettings {
    return {
        ...existingAnalyticalModelSettings,
        degenerateTriangleSettings: {
            needleThresholdRatio: stringAsFiniteNumber(
                detectionSettings.degenerateTriangleSettings.needleThresholdRatio,
            ),
            capMinAngleDegrees: stringAsFiniteNumber(
                detectionSettings.degenerateTriangleSettings.capMinAngleDegrees,
            ),
            needleCollapseLength: stringAsFiniteNumber(
                detectionSettings.degenerateTriangleSettings.needleCollapseLength,
            ),
        },
        holeSettings: {
            holeSizeRatioTolerance: stringAsFiniteNumber(
                detectionSettings.holeSettings.holeSizeRatioTolerance,
            ),
        },
        selfIntersectionSettings: {
            tolerance: stringAsFiniteNumber(detectionSettings.selfIntersectionSettings.tolerance),
        },
    };
}

export const defaultDetectionSettingsState = detectionSettingsFromAnalyticalModelSettings(
    defaultAnalyticalModelSettings,
);

type SelectorTypeString = (state: RootState) => string;
type SelectorTypeBoolean = (state: RootState) => boolean;

const detectionSettingsState = (state: RootState): DetectionSettingsState =>
    state.detectionSettings;

export const selectAllDetectionSettings = detectionSettingsState;

export const selectNeedleThresholdRatio: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.needleThresholdRatio,
);

export const selectNeedleThresholdRatioValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.needleThresholdRatioValid,
);

export const selectCapMinAngleDegrees: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.capMinAngleDegrees,
);

export const selectCapMinAngleDegreesValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.capMinAngleDegreesValid,
);

export const selectNeedleCollapseLength: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.needleCollapseLength,
);

export const selectNeedleCollapseLengthValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.degenerateTriangleSettings.needleCollapseLengthValid,
);
export const selectHoleSizeRatioTolerance: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) => detectionSettingsStateRoot.holeSettings.holeSizeRatioTolerance,
);

export const selectHoleSizeRatioToleranceValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.holeSettings.holeSizeRatioToleranceValid,
);

export const selectSelfIntersectionTolerance: SelectorTypeString = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) => detectionSettingsStateRoot.selfIntersectionSettings.tolerance,
);

export const selectSelfIntersectionToleranceValid: SelectorTypeBoolean = createSelector(
    detectionSettingsState,
    (detectionSettingsStateRoot) =>
        detectionSettingsStateRoot.selfIntersectionSettings.toleranceValid,
);
