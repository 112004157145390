import { WDSThemeProvider } from '@local/web-design-system-2';
import InfoIcon from '@local/web-design-system-2/dist/icons/Info';
import ReplayOutlined from '@mui/icons-material/ReplayOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash-es/isEqual';
import { useContext } from 'react';

import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import type { GtmAnalyticalModel, GtmAnalyticalModelSettings } from 'src/gtmProject/Project.types';
import {
    detectorTargetObjectsInAnalyticalModel,
    useDefectsLoadingManager,
} from 'src/hooks/defects/useDefectsLoadingManager';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { updateModelAtIndexForCurrentProject } from 'src/store/project/projectSlice';
import { selectCurrentProjectModels } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import type { DetectionSettingsState } from 'src/store/ui/detectionSettings';
import {
    setAllDetectionSettings,
    setCapMinAngleDegrees,
    setCapMinAngleDegreesValid,
    setNeedleThresholdRatio,
    setNeedleThresholdRatioValid,
    setNeedleCollapseLength,
    setNeedleCollapseLengthValid,
    setHoleSizeRatioTolerance,
    setHoleSizeRatioToleranceValid,
    setSelfIntersectionTolerance,
    setSelfIntersectionToleranceValid,
} from 'src/store/ui/detectionSettings';
import {
    detectionSettingsFromAnalyticalModelSettings,
    defaultDetectionSettingsState,
    selectAllDetectionSettings,
    selectCapMinAngleDegrees,
    selectCapMinAngleDegreesValid,
    selectHoleSizeRatioTolerance,
    selectHoleSizeRatioToleranceValid,
    selectNeedleCollapseLength,
    selectNeedleCollapseLengthValid,
    selectNeedleThresholdRatio,
    selectNeedleThresholdRatioValid,
    selectSelfIntersectionTolerance,
    selectSelfIntersectionToleranceValid,
    analyticalModelSettingsFromDetectionSettings,
} from 'src/store/ui/detectionSettings/selectors';
import { CANCEL_LABEL, RESET_LABEL, SAVE_LABEL } from 'src/strings';
import { DEFAULT_SETTING_FIELD_WIDTH_PX } from 'src/styles';
import { assert } from 'src/utils/gtmAssert';
import { stringRepresentsFiniteNumber } from 'src/utils/math';

import {
    DETECTION_SETTINGS_PANEL_TITLE,
    HOLE_SETTINGS_HEADER,
    HOLE_SIZE_RATIO,
    HOLE_SIZE_RATIO_INFO,
    CAP_MINIMUM_ANGLE,
    CAP_MINIMUM_ANGLE_INFO,
    DEGENERATE_TRIANGLE_SETTINGS_HEADER,
    NEEDLE_COLLAPSE_LENGTH,
    NEEDLE_COLLAPSE_LENGTH_INFO,
    NEEDLE_THRESHOLD_RATIO,
    NEEDLE_THRESHOLD_RATIO_INFO,
    SELF_INTERSECTION_SETTINGS_HEADER,
    TOLERANCE,
    TOLERANCE_INFO,
    getDetectionSettingsUpdateDescription,
} from './DetectionSettingsPanel.constants';
import { useStyles } from './DetectionSettingsPanel.styles';

type ValueRangeRestriction = {
    // Examples to illustrate meaning:
    //   inclusiveMin == 4.0 then 4.0 itself is within the restricted range
    //   exclusiveMin == 4.0 then 4.0 itself is NOT within the restricted range, but 4.000001 is
    inclusiveMin?: number;
    exclusiveMin?: number;
    inclusiveMax?: number;
    exclusiveMax?: number;
};

const settingsGroupToDetectorAction: { [key: string]: GtmMeshDetectorAction } = {
    degenerateTriangleSettings: GtmMeshDetectorAction.DetectDegenerateTris,
    holeSettings: GtmMeshDetectorAction.DetectHoles,
    selfIntersectionSettings: GtmMeshDetectorAction.DetectSelfIntersections,
};

function detectorsWithChangedSettings(
    originalDetectionSettings: GtmAnalyticalModelSettings,
    updatedDetectionSettings: GtmAnalyticalModelSettings,
) {
    const outdatedDetectors: GtmMeshDetectorAction[] = [];

    Object.keys(originalDetectionSettings).forEach((group) => {
        const originalSettings =
            originalDetectionSettings[group as keyof GtmAnalyticalModelSettings];
        const updatedSettings = updatedDetectionSettings[group as keyof GtmAnalyticalModelSettings];
        if (!isEqual(originalSettings, updatedSettings)) {
            assert(
                group in settingsGroupToDetectorAction,
                'Changed settings do not map to a detector!',
            );
            const affectedDetector = settingsGroupToDetectorAction[group];
            outdatedDetectors.push(affectedDetector);
        }
    });

    return outdatedDetectors;
}

export function DetectionSettingsPanel({
    modelIndex,
    anchorEl,
    onClose,
}: {
    modelIndex: number;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}) {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const models = useAppSelector(selectCurrentProjectModels);
    const allSettings = useAppSelector(selectAllDetectionSettings);
    const { syncProject } = useProjectSynchronizer();
    const { runSelectedDetectors } = useDefectsLoadingManager();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (anchorEl === null) return null;

    // Track the initial detection settings from the model config to support disabling 'Save'
    // button when there are no changes.
    const currentAnalyticalModel = models[modelIndex] as GtmAnalyticalModel;
    const currentAnalyticalModelSettings = currentAnalyticalModel.analyticalModelSettings;
    const initialDetectionSettings = detectionSettingsFromAnalyticalModelSettings(
        currentAnalyticalModelSettings,
    );

    const onSave = () => {
        onClose();
        const updatedSettings = analyticalModelSettingsFromDetectionSettings(
            currentAnalyticalModelSettings,
            allSettings,
        );

        dispatch(
            updateModelAtIndexForCurrentProject([
                { analyticalModelSettings: updatedSettings },
                modelIndex,
            ]),
        );
        syncProject({
            description: getDetectionSettingsUpdateDescription(models[modelIndex]?.name as string),
        });

        // Rerun the detectors for which settings were changed.
        const detectorTargetObjects =
            detectorTargetObjectsInAnalyticalModel(currentAnalyticalModel);
        const outdatedDetectors = detectorsWithChangedSettings(
            currentAnalyticalModelSettings,
            updatedSettings,
        );
        detectorTargetObjects.forEach((object) => {
            runSelectedDetectors(object, outdatedDetectors, updatedSettings);
        });
    };

    // TODO: GEOM-718
    // The detection settings menu is supposed to open to the right of the models panel with a 4px
    // space in between panels. For now we hardcode the offset, which is fragile ...
    const offsetToRight = 89;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            anchorReference="anchorPosition"
            anchorPosition={{
                top: anchorEl ? anchorEl.getBoundingClientRect().top : 0,
                left: anchorEl ? anchorEl.getBoundingClientRect().left + offsetToRight : 0,
            }}
        >
            <Box className={classes.root}>
                <WDSThemeProvider themeMode={appTheme}>
                    <Paper elevation={16}>
                        <DialogTitle sx={(theme) => ({ padding: theme.spacing(2) })}>
                            {DETECTION_SETTINGS_PANEL_TITLE}
                        </DialogTitle>
                        <Divider />
                        <DegenerateTrianglesSettings />
                        <Divider />
                        <HolesSettings />
                        <Divider />
                        <SelfIntersectionSettings />
                        <Divider />
                        <ResetCancelSaveSettings
                            onClose={onClose}
                            onSave={onSave}
                            initialSettings={initialDetectionSettings}
                        />
                    </Paper>
                </WDSThemeProvider>
            </Box>
        </Popover>
    );
}

function DegenerateTrianglesSettings() {
    const { classes } = useStyles();
    const theme = useTheme();

    return (
        <Stack direction="column" className={classes.settingsSection}>
            <Typography variant="overline" color="secondary" sx={{ padding: theme.spacing(1, 0) }}>
                {DEGENERATE_TRIANGLE_SETTINGS_HEADER}
            </Typography>
            <NeedleThesholdInput />
            <NeedleCollapseLengthInput />
            <CapMinAngleInput />
        </Stack>
    );
}

function HolesSettings() {
    const { classes } = useStyles();
    const theme = useTheme();
    return (
        <Stack direction="column" className={classes.settingsSection}>
            <Typography variant="overline" color="secondary" sx={{ padding: theme.spacing(1, 0) }}>
                {HOLE_SETTINGS_HEADER}
            </Typography>
            <HoleSizeRatioInput />
        </Stack>
    );
}

function SelfIntersectionSettings() {
    const { classes } = useStyles();
    const theme = useTheme();
    return (
        <Stack direction="column" className={classes.settingsSection}>
            <Typography variant="overline" color="secondary" sx={{ padding: theme.spacing(1, 0) }}>
                {SELF_INTERSECTION_SETTINGS_HEADER}
            </Typography>
            <SelfIntersectionToleranceInput />
        </Stack>
    );
}

function ResetCancelSaveSettings({
    onClose,
    onSave,
    initialSettings,
}: {
    onClose: () => void;
    onSave: () => void;
    initialSettings: DetectionSettingsState;
}) {
    const allSettings = useAppSelector(selectAllDetectionSettings);
    const dispatch = useAppDispatch();
    const onReset = () => {
        dispatch(setAllDetectionSettings(defaultDetectionSettingsState));
    };
    const onCancel = () => {
        onClose();
    };

    const allValid =
        allSettings.degenerateTriangleSettings.needleThresholdRatioValid &&
        allSettings.degenerateTriangleSettings.needleCollapseLengthValid &&
        allSettings.degenerateTriangleSettings.capMinAngleDegreesValid &&
        allSettings.holeSettings.holeSizeRatioToleranceValid &&
        allSettings.selfIntersectionSettings.toleranceValid;
    const isDisabled = !allValid || isEqual(allSettings, initialSettings);

    return (
        <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
                variant="text"
                size="medium"
                color="secondary"
                onClick={onReset}
                startIcon={<ReplayOutlined />}
                sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
            >
                {RESET_LABEL}
            </Button>
            <Button variant="text" size="medium" color="secondary" onClick={onCancel}>
                {CANCEL_LABEL}
            </Button>
            <Button
                variant="text"
                size="medium"
                color="primary"
                onClick={onSave}
                disabled={isDisabled}
            >
                {SAVE_LABEL}
            </Button>
        </DialogActions>
    );
}

function NeedleThesholdInput() {
    const { classes } = useStyles();
    const needleThresholdRatio = useAppSelector(selectNeedleThresholdRatio);
    const inputIsValid = useAppSelector(selectNeedleThresholdRatioValid);
    const dispatch = useAppDispatch();
    const validRange: ValueRangeRestriction = { inclusiveMin: 2.0 };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = event.target.value;
        dispatch(setNeedleThresholdRatio(event.target.value));
        const isValid =
            stringRepresentsFiniteNumber(newValue) &&
            numberValueInRange(Number(newValue), validRange);
        dispatch(setNeedleThresholdRatioValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {NEEDLE_THRESHOLD_RATIO}
            </Typography>
            <TextField
                value={needleThresholdRatio}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">1:</InputAdornment>,
                }}
            />
            <Tooltip title={NEEDLE_THRESHOLD_RATIO_INFO}>
                <IconButton size="small">
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function NeedleCollapseLengthInput() {
    const { classes } = useStyles();
    const needleCollapseLength = useAppSelector(selectNeedleCollapseLength);
    const inputIsValid = useAppSelector(selectNeedleCollapseLengthValid);
    const dispatch = useAppDispatch();
    const validRange: ValueRangeRestriction = { inclusiveMin: 0.0 };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = event.target.value;
        dispatch(setNeedleCollapseLength(event.target.value));
        const isValid =
            stringRepresentsFiniteNumber(newValue) &&
            numberValueInRange(Number(newValue), validRange);
        dispatch(setNeedleCollapseLengthValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {NEEDLE_COLLAPSE_LENGTH}
            </Typography>
            <TextField
                value={needleCollapseLength}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
            />
            <Tooltip title={NEEDLE_COLLAPSE_LENGTH_INFO}>
                <IconButton size="small">
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function CapMinAngleInput() {
    const { classes } = useStyles();
    const capMinAngleDegrees = useAppSelector(selectCapMinAngleDegrees);
    const inputIsValid = useAppSelector(selectCapMinAngleDegreesValid);
    const dispatch = useAppDispatch();
    const validRange: ValueRangeRestriction = { inclusiveMin: 90.0, inclusiveMax: 180.0 };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = event.target.value;
        dispatch(setCapMinAngleDegrees(newValue));
        const isValid =
            stringRepresentsFiniteNumber(newValue) &&
            numberValueInRange(Number(newValue), validRange);
        dispatch(setCapMinAngleDegreesValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {CAP_MINIMUM_ANGLE}
            </Typography>
            <TextField
                value={capMinAngleDegrees}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">°</InputAdornment>,
                }}
            />
            <Tooltip title={CAP_MINIMUM_ANGLE_INFO}>
                <IconButton size="small">
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function HoleSizeRatioInput() {
    const { classes } = useStyles();
    const holeSizeRatioTolerance = useAppSelector(selectHoleSizeRatioTolerance);
    const inputIsValid = useAppSelector(selectHoleSizeRatioToleranceValid);
    const dispatch = useAppDispatch();
    const validRange: ValueRangeRestriction = { exclusiveMin: 0.0, inclusiveMax: 1.0 };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = event.target.value;
        dispatch(setHoleSizeRatioTolerance(event.target.value));
        const isValid =
            stringRepresentsFiniteNumber(newValue) &&
            numberValueInRange(Number(newValue), validRange);
        dispatch(setHoleSizeRatioToleranceValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {HOLE_SIZE_RATIO}
            </Typography>
            <TextField
                value={holeSizeRatioTolerance}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">1:</InputAdornment>,
                }}
            />
            <Tooltip title={HOLE_SIZE_RATIO_INFO}>
                <IconButton size="small">
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function SelfIntersectionToleranceInput() {
    const { classes } = useStyles();
    const tolerance = useAppSelector(selectSelfIntersectionTolerance);
    const inputIsValid = useAppSelector(selectSelfIntersectionToleranceValid);
    const dispatch = useAppDispatch();
    const validRange: ValueRangeRestriction = { exclusiveMin: 0.0 };

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = event.target.value;
        dispatch(setSelfIntersectionTolerance(event.target.value));
        const isValid =
            stringRepresentsFiniteNumber(newValue) &&
            numberValueInRange(Number(newValue), validRange);
        dispatch(setSelfIntersectionToleranceValid(isValid));
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography variant="caption" color="secondary" sx={{ flex: 1 }}>
                {TOLERANCE}
            </Typography>
            <TextField
                value={tolerance}
                onChange={onChange}
                type="text"
                variant="outlined"
                error={!inputIsValid}
                size="small"
                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
            />
            <Tooltip title={TOLERANCE_INFO}>
                <IconButton size="small">
                    <InfoIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function numberValueInRange(value: number, rangeRestriction: ValueRangeRestriction): boolean {
    // If the range restriction property is defined and not null, check if it holds
    if (rangeRestriction.inclusiveMin != null && value < rangeRestriction.inclusiveMin)
        return false;
    if (rangeRestriction.exclusiveMin != null && value <= rangeRestriction.exclusiveMin)
        return false;
    if (rangeRestriction.inclusiveMax != null && value > rangeRestriction.inclusiveMax)
        return false;
    if (rangeRestriction.exclusiveMax != null && value >= rangeRestriction.exclusiveMax)
        return false;
    return true;
}
