import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const SidebarRight = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <path d="M14.9707 22.75H8.9707C3.5407 22.75 1.2207 20.43 1.2207 15V9C1.2207 3.57 3.5407 1.25 8.9707 1.25H14.9707C20.4007 1.25 22.7207 3.57 22.7207 9V15C22.7207 20.43 20.4107 22.75 14.9707 22.75ZM8.9707 2.75C4.3607 2.75 2.7207 4.39 2.7207 9V15C2.7207 19.61 4.3607 21.25 8.9707 21.25H14.9707C19.5807 21.25 21.2207 19.61 21.2207 15V9C21.2207 4.39 19.5807 2.75 14.9707 2.75H8.9707Z" />
        <path d="M14.9707 22.75C14.5607 22.75 14.2207 22.41 14.2207 22V2C14.2207 1.59 14.5607 1.25 14.9707 1.25C15.3807 1.25 15.7207 1.59 15.7207 2V22C15.7207 22.41 15.3907 22.75 14.9707 22.75Z" />
        <path d="M7.97016 15.3099C7.78016 15.3099 7.59016 15.2399 7.44016 15.0899C7.15016 14.7999 7.15016 14.3199 7.44016 14.0299L9.47016 11.9999L7.44016 9.96988C7.15016 9.67988 7.15016 9.19988 7.44016 8.90988C7.73016 8.61988 8.21016 8.61988 8.50016 8.90988L11.0602 11.4699C11.3502 11.7599 11.3502 12.2399 11.0602 12.5299L8.50016 15.0899C8.36016 15.2399 8.17016 15.3099 7.97016 15.3099Z" />
    </SvgIcon>
);
