import type { ThemeMode } from '@local/web-design-system-2/dist/theme';
import { createContext, useMemo, useState } from 'react';

export interface WDS2ThemeProviderProps {
    theme: ThemeMode;
    toggleTheme: () => void;
}

function detectSystemTheme(): 'light' | 'dark' {
    const darkThemeSystem = window.matchMedia('(prefers-color-scheme: dark)');
    return darkThemeSystem.matches ? 'dark' : 'light';
}

const initialTheme = detectSystemTheme();

// Create the theme context
export const WDS2ThemeContext = createContext<WDS2ThemeProviderProps>({
    theme: initialTheme,
    toggleTheme: () => {},
});

// Create the theme provider component
export const WDS2ThemeProvider = ({ children }: { children: React.ReactNode }) => {
    const [theme, setTheme] = useState<ThemeMode>(initialTheme);

    // Function to toggle the theme
    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
    };

    const contextValue = useMemo(() => ({ theme, toggleTheme }), [theme]);

    return <WDS2ThemeContext.Provider value={contextValue}>{children}</WDS2ThemeContext.Provider>;
};
