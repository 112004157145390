import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton/IconButton';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SidebarLeft } from 'src/assets/SidebarLeft';
import { SidebarRight } from 'src/assets/SidebarRight';
import type { GtmProject } from 'src/gtmProject';
import { useProjectManager } from 'src/hooks/project';
import { selectGtmProjects, selectWorkspaceName } from 'src/store/evo/selectors';
import { selectCurrentProjectData } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    selectShouldMinimizeProjectPanel,
    toggleProjectPanelMinimize,
} from 'src/store/ui/projectPanel';
import { getNextSequentialName } from 'src/utils/stringHelpers';
import { DeleteDialog } from 'src/visualization/ProjectPanel/components/DeleteDialog';
import { PanelItemMenu } from 'src/visualization/ProjectPanel/components/PanelItemMenu';
import { useNavigateToProject } from 'src/visualization/ProjectPanel/components/useNavigateToProject';
import {
    AT_LEAST_ONE_PROJECT_REQUIRED_ERROR,
    DELETE_PROJECT_TITLE,
    getDeleteProjectMessage,
    getProjectCreationErrorMessage,
    INVALID_NEW_PROJECT_NAME_MESSAGE,
    NEW_PROJECT_NAME,
    PROJECT_RENAME_ERROR,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function ProjectSelector() {
    const dispatch = useAppDispatch();
    const applyTrace = useTrace('project-selector');
    const { projectName: urlProjectName } = useParams();
    const { initializeNewProjectFile, deleteProjectFile, renameProjectFile } = useProjectManager();
    const { addMessage } = useMessagesContext();
    const workspaceName = useAppSelector(selectWorkspaceName);
    const selectedProject = useAppSelector(selectCurrentProjectData);
    const [newProjectName, setNewProjectName] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [projectToDelete, setProjectToDelete] = useState<GtmProject | null>(null);
    const [isSavingProject, setIsSavingProject] = useState(false);
    const shouldMinimizeProjectPanel = useAppSelector(selectShouldMinimizeProjectPanel);
    const { navigateToProjectURL } = useNavigateToProject();
    const projectNames = useAppSelector(selectGtmProjects) ?? [];

    const isNewNameInvalid = useMemo(() => {
        let projectNamesToCheck = projectNames;
        if (isRenaming) {
            projectNamesToCheck = projectNames.filter((name) => name !== selectedProject.name);
        }
        return projectNamesToCheck.includes(newProjectName);
    }, [projectNames, isRenaming, selectedProject, newProjectName]);

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: SelectChangeEvent) => {
        const newSelection = event.target.value;

        if (newSelection === urlProjectName) {
            return;
        }

        navigateToProjectURL(newSelection);
    };

    const handleAddNewProject = () => {
        setNewProjectName(getNextSequentialName(NEW_PROJECT_NAME, projectNames));
        setIsAdding(true);
    };

    const handleProjectNameOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewProjectName(event.target.value);
    };

    const handleAddProjectConfirm = async () => {
        setIsSavingProject(true);
        try {
            await initializeNewProjectFile(newProjectName);
            navigateToProjectURL(newProjectName);
        } catch (e) {
            addMessage({
                message: getProjectCreationErrorMessage(newProjectName),
                type: NotificationType.ERROR,
            });
        } finally {
            setNewProjectName('');
            setIsAdding(false);
            setIsSavingProject(false);
        }
    };

    const handleRenameProject = () => {
        setNewProjectName(selectedProject.name);
        setIsRenaming(true);
    };

    const handleRenameProjectConfirm = async () => {
        setIsSavingProject(true);
        try {
            await renameProjectFile(selectedProject, newProjectName);
            navigateToProjectURL(newProjectName);
        } catch (e) {
            addMessage({
                message: PROJECT_RENAME_ERROR,
                type: NotificationType.ERROR,
            });
        } finally {
            setNewProjectName('');
            setIsRenaming(false);
            setIsSavingProject(false);
        }
    };

    const handleCancelAddOrRename = () => {
        setNewProjectName('');
        setIsAdding(false);
        setIsRenaming(false);
    };

    const handleAddOrRenameConfirm = () => {
        if (isAdding) {
            handleAddProjectConfirm();
        } else if (isRenaming) {
            handleRenameProjectConfirm();
        }
    };

    const handleProjectNameOnKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleAddOrRenameConfirm();
        } else if (event.key === 'Escape') {
            handleCancelAddOrRename();
        }
    };

    const handleDeleteProject = () => {
        if (projectNames.length === 1) {
            addMessage({
                message: AT_LEAST_ONE_PROJECT_REQUIRED_ERROR,
                type: NotificationType.ERROR,
            });
            return;
        }
        setProjectToDelete(selectedProject);
    };

    const handleDeleteProjectConfirm = async () => {
        if (!urlProjectName) {
            return;
        }

        await deleteProjectFile(urlProjectName);
        const newSelectedProject = projectNames.filter((name) => name !== urlProjectName)[0];
        navigateToProjectURL(newSelectedProject);
        setProjectToDelete(null);
    };

    const handleDeleteProjectCancel = () => {
        setProjectToDelete(null);
    };

    const handleToggleProjectPanelMinimize = () => {
        dispatch(toggleProjectPanelMinimize());
    };

    return (
        <>
            <Box p={2}>
                {isAdding || isRenaming ? (
                    <Stack direction="row" justifyContent="space-between">
                        <TextField
                            automation-id={applyTrace('add-or-rename-input')}
                            variant="standard"
                            size="small"
                            value={newProjectName}
                            onChange={handleProjectNameOnChange}
                            onKeyUp={handleProjectNameOnKeyUp}
                            error={isNewNameInvalid && !isSavingProject}
                            helperText={
                                isNewNameInvalid && !isSavingProject
                                    ? INVALID_NEW_PROJECT_NAME_MESSAGE
                                    : null
                            }
                        />
                        {isSavingProject ? (
                            <CircularProgress
                                automation-id={applyTrace('saving-indicator')}
                                sx={(theme) => ({ marginLeft: theme.spacing(3) })}
                                size={24}
                            />
                        ) : (
                            <Stack direction="row">
                                <IconButton
                                    automation-id={applyTrace('add-or-rename-confirm-button')}
                                    size="small"
                                    disabled={
                                        isNewNameInvalid ||
                                        newProjectName.trim() === '' ||
                                        newProjectName === selectedProject.name
                                    }
                                    onClick={handleAddOrRenameConfirm}
                                >
                                    <CheckIcon
                                        color={
                                            isNewNameInvalid ||
                                            newProjectName.trim() === '' ||
                                            newProjectName === selectedProject.name
                                                ? 'disabled'
                                                : 'primary'
                                        }
                                    />
                                </IconButton>
                                <IconButton
                                    automation-id={applyTrace('add-or-rename-cancel-button')}
                                    size="small"
                                    onClick={handleCancelAddOrRename}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Stack>
                        )}
                    </Stack>
                ) : (
                    <>
                        <Stack sx={{ justifyContent: 'space-between' }} direction="row">
                            <FormControl>
                                <Select
                                    automation-id={applyTrace('select')}
                                    inputProps={{ sx: { paddingBottom: 0 } }}
                                    disableUnderline
                                    variant="standard"
                                    size="small"
                                    value={urlProjectName ?? ''}
                                    onChange={handleChange}
                                    MenuProps={{ slotProps: { paper: { sx: { maxHeight: 400 } } } }}
                                >
                                    {projectNames.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Stack direction="row">
                                <IconButton
                                    automation-id={applyTrace('menu-button')}
                                    sx={{ padding: 0 }}
                                    size="small"
                                    onClick={handleOpenMenu}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    automation-id={applyTrace('toggle-project-panel')}
                                    sx={{ padding: 0 }}
                                    size="small"
                                    onClick={handleToggleProjectPanelMinimize}
                                >
                                    {shouldMinimizeProjectPanel ? (
                                        <SidebarRight fontSize="small" color="primary" />
                                    ) : (
                                        <SidebarLeft fontSize="small" color="primary" />
                                    )}
                                </IconButton>
                            </Stack>
                        </Stack>
                        <Typography sx={{ display: 'block' }} color="secondary" variant="caption">
                            {workspaceName}
                        </Typography>
                        <PanelItemMenu
                            anchorEl={anchorEl}
                            onClose={handleCloseMenu}
                            onAdd={handleAddNewProject}
                            onDelete={projectNames.length > 1 ? handleDeleteProject : undefined}
                            onRename={handleRenameProject}
                        />
                    </>
                )}
            </Box>
            <DeleteDialog
                title={DELETE_PROJECT_TITLE}
                shouldOpen={Boolean(projectToDelete)}
                message={getDeleteProjectMessage(selectedProject.name)}
                handleCancel={handleDeleteProjectCancel}
                handleDelete={handleDeleteProjectConfirm}
            />
        </>
    );
}
