import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { ButtonProps } from '@mui/material/Button/Button';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import type { ColorPickerBaseProps } from 'react-colorful/dist/types';

import { CANCEL_LABEL, SAVE_LABEL } from 'src/strings';

const DEFAULT_CONTAINER_HEIGHT = '312px';
const DEFAULT_CONTAINER_WIDTH = '234px';
const DEFAULT_INITIAL_COLOR = '#FFFFFF';

export interface ColorPickerProps {
    open: boolean;
    onSave: (color: string) => void;
    onClose: () => void;
    initialColor?: string;
    'automation-id'?: string;
    sx?: SxProps<Theme>;
}

export const ColorPicker = ({
    open,
    onSave,
    onClose,
    initialColor,
    sx,
    'automation-id': automationId,
}: ColorPickerProps) => {
    const [selectedColor, setSelectedColor] = useState(initialColor ?? DEFAULT_INITIAL_COLOR);

    useEffect(() => {
        if (initialColor) {
            setSelectedColor(initialColor);
        }
    }, [initialColor]);

    const handleOnChange = (newColor: string) => {
        setSelectedColor(newColor.toUpperCase());
    };

    const handleOnSave = () => {
        onSave(selectedColor);
        onClose();
    };

    const handleOnCancel = () => {
        // reopening the dialog after closing without saving the selection should show the initial color
        setSelectedColor(initialColor ?? DEFAULT_INITIAL_COLOR);
        onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <Paper
            automation-id={automationId}
            elevation={16}
            sx={[
                {
                    height: DEFAULT_CONTAINER_HEIGHT,
                    width: DEFAULT_CONTAINER_WIDTH,
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Stack sx={{ flexFlow: 'column', height: '100%' }}>
                <Stack
                    direction="row"
                    sx={(theme) => ({
                        padding: theme.spacing(2, 2, 0, 2),
                    })}
                >
                    <Icon
                        sx={{
                            fontSize: '18px',
                            borderRadius: '4px',
                            backgroundColor: selectedColor,
                        }}
                    />
                    <Typography
                        variant="body2"
                        sx={(theme) => ({
                            color: theme.palette.action.active,
                            marginLeft: theme.spacing(1),
                        })}
                    >
                        {selectedColor}
                    </Typography>
                </Stack>
                <Box
                    sx={(theme) => ({
                        marginTop: theme.spacing(1.5),
                        marginBottom: theme.spacing(2),
                        flexGrow: 1,
                        padding: theme.spacing(0, 2),
                    })}
                >
                    <CustomHexColorPicker color={selectedColor} onChange={handleOnChange} />
                </Box>
                <Divider />
                <Stack
                    direction="row"
                    sx={(theme) => ({
                        padding: theme.spacing(1),
                        justifyContent: 'flex-end',
                    })}
                >
                    <CustomButton
                        automation-id={`${automationId}-cancel-button`}
                        sx={(theme) => ({ marginRight: theme.spacing(1) })}
                        onClick={handleOnCancel}
                    >
                        {CANCEL_LABEL}
                    </CustomButton>
                    <CustomButton
                        automation-id={`${automationId}-save-button`}
                        onClick={handleOnSave}
                    >
                        {SAVE_LABEL}
                    </CustomButton>
                </Stack>
            </Stack>
        </Paper>
    );
};

const CustomHexColorPicker = (props: Partial<ColorPickerBaseProps<string>>) => (
    <Box
        sx={(theme) => ({
            height: '100%',
            width: '100%',
            '& .react-colorful': {
                height: '100%',
                width: '100%',
            },
            '& .react-colorful__hue': {
                marginTop: theme.spacing(1.5),
                height: '6px',
                borderRadius: '100px',
            },
            '& .react-colorful__saturation': {
                borderRadius: theme.spacing(0.5),
                borderBottom: 'none',
            },
            '& .react-colorful__pointer': {
                borderRadius: theme.spacing(2.5),
                height: '12px',
                width: '12px',
            },
        })}
    >
        <HexColorPicker {...props} />
    </Box>
);

const CustomButton = ({ children, sx, ...rest }: Omit<ButtonProps, 'size'>) => (
    <Button
        size="small"
        sx={[
            (theme) => ({
                minWidth: 0,
                padding: theme.spacing(0.75, 1),
                fontSize: theme.typography.body1,
                fontWeight: theme.typography.fontWeightBold,
            }),
            ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
    >
        {children}
    </Button>
);
