// We will want to autogenerate these types and the API once we are in Evo compute.
// For now, let's expose a few items that we need to interact with the API for a
// prototype demo using our monolithic lambda.

import type { ObjectIdWithVersion, ObjectId } from 'src/types/core.types';

export type RequestObject = ObjectIdWithVersion & { __brand: 'RequestObject' };

export enum GtmMeshGetMeshDataAction {
    GetMeshData = 'GetMeshData',
}

export enum GtmMeshDetectorAction {
    DetectDegenerateTris = 'DetectDegenerateTris',
    DetectDuplicatePoints = 'DetectDuplicatePoints',
    DetectDuplicateTris = 'DetectDuplicateTris',
    DetectFins = 'DetectFins',
    DetectHoles = 'DetectHoles',
    DetectInconsistentlyOrientedTris = 'DetectInconsistentlyOrientedTris',
    DetectNonManifoldEdges = 'DetectNonManifoldEdges',
    DetectNonManifoldVertices = 'DetectNonManifoldVertices',
    // TODO: Update name of `DetectRedundantSurfaces` detector (GEOM-306)
    DetectNonPartitioningSurfaces = 'DetectRedundantSurfaces',
    DetectSelfIntersections = 'DetectSelfIntersections',
}

export enum GtmMeshTransformationAction {
    AddToAggregateGeom = 'AddToAggregateGeom',
    Clip = 'Clip',
    CreateAnalyticalBoundary = 'CreateAnalyticalBoundary',
    CreateCrossSection = 'CreateCrossSection',
    FillHoles = 'FillHoles',
    InitAggregateGeom = 'InitAggregateGeom',
    ParameterizeVolumes = 'ParameterizeVolumes',
    Remesh = 'Remesh',
    RemoveDegenerateTris = 'RemoveDegenerateTris',
    RemoveTris = 'RemoveTris',
    RemoveUnusedPoints = 'RemoveUnusedPoints',
    SeparateVolumes = 'SeparateVolumes',
    Translate = 'Translate',
    UploadMeshData = 'UploadMeshData',
    Intersect = 'Intersect',
}

export type GtmMeshRequestAction =
    | GtmMeshGetMeshDataAction
    | GtmMeshDetectorAction
    | GtmMeshTransformationAction;

type EmptyObject = Record<string, never>;

type GtmMeshGetMeshDataParams = EmptyObject;

export interface GtmMeshDetectHolesParams {
    holeSizeRatioTolerance: number;
}

export interface GtmMeshDetectDegenerateTriangleParams {
    needleThresholdRatio: number;
    capMinAngleDegrees: number;
}

export interface GtmMeshDetectSelfIntersectionsParams {
    tolerance: number;
}

export type GtmMeshDetectorParams =
    | GtmMeshDetectHolesParams
    | GtmMeshDetectDegenerateTriangleParams
    | GtmMeshDetectSelfIntersectionsParams
    | EmptyObject;

interface Point {
    x: number;
    y: number;
    z: number;
}

type Vector = Point;

interface GtmMeshTranslationParams {
    delta: Vector;
}

export interface GtmRemeshParams {
    patchAngleTolerance: number;
    maxChordalError: number;
    strainTolerance: number;
    isClosed: boolean;
    initialCleanup: boolean;
    lineProximityDetection: boolean;
    optimize: boolean;
    shapeQualityWeight: number;
    targetH: number;
}

interface GtmMeshRemoveTrianglesParams {
    triInds: number[];
}

interface GtmMeshClipParams {
    minPoint: Point;
    maxPoint: Point;
    rotationAngle: number;
}

interface GtmCreateAnalyticalBoundaryParams extends GtmMeshClipParams {
    projectName: string;
    tolerance: number;
    extensions?: Record<string, any>;
}

export interface GtmInitAggregateGeomParams {
    minPoint: Point;
    maxPoint: Point;
    rotationAngle: number;
    boundaryId: ObjectId;
}

interface GtmAddToAggregateGeomParams {
    aggregateGeomId: RequestObject;
    tolerance: number;
    noSelfIntersectionsInParts: boolean;
}

export interface GtmMeshRemoveDegenerateTriangleParams {
    needleThresholdRatio: number;
    capMinAngleDegrees: number;
    needleCollapseLength: number;
}

export enum GtmMeshFillMode {
    Fill = 'Fill',
    FillAndRefine = 'FillAndRefine',
    FillRefineAndFair = 'FillRefineAndFair',
}

export interface GtmMeshFillHoleParams {
    fillMode: GtmMeshFillMode;
    edges: [number, number] | [number, number][];
}

interface GtmUploadMeshDataParams {
    file_id: string;
    name: string;
}

interface GtmParameterizeVolumesParams {
    name: string;
    distanceUnit: string;
}

interface GtmCreateCrossSectionParams {
    name: string;
    sectionOrigin: Point;
    sectionDirection: Point;
}

interface GtmMeshIntersectParams {
    tolerance: number;
    assumeNoSelfIntersectionsInParts: boolean;
}

export type GtmMeshTransformationParams =
    | GtmMeshTranslationParams
    | GtmMeshRemoveTrianglesParams
    | GtmRemeshParams
    | GtmMeshClipParams
    | GtmInitAggregateGeomParams
    | GtmAddToAggregateGeomParams
    | GtmMeshRemoveDegenerateTriangleParams
    | GtmMeshFillHoleParams
    | GtmCreateAnalyticalBoundaryParams
    | GtmUploadMeshDataParams
    | GtmParameterizeVolumesParams
    | GtmCreateCrossSectionParams
    | GtmMeshIntersectParams
    | EmptyObject;

type GtmMeshRequestParams =
    | GtmMeshGetMeshDataParams
    | GtmMeshDetectorParams
    | GtmMeshTransformationParams;

export interface GtmBaseRequestBody {
    gooseContext: GtmGooseContext;
    action: GtmMeshRequestAction;
    objects: RequestObject[];
    params: GtmMeshRequestParams;
}

export interface GtmMeshDataRequestBody extends GtmBaseRequestBody {
    action: GtmMeshGetMeshDataAction;
    params: GtmMeshGetMeshDataParams;
}

export interface GtmMeshData {
    vertices: Float32Array;
    triangles: Int32Array;
}

export interface GtmMeshDetectorRequestBody extends GtmBaseRequestBody {
    action: GtmMeshDetectorAction;
    params: GtmMeshDetectorParams;
}

export interface GtmMeshDetectionData
    extends Array<{
        points: number[];
        edges: [number, number][];
        triangles: number[];
    }> {}

export interface GtmMeshTransformationRequestBody extends GtmBaseRequestBody {
    action: GtmMeshTransformationAction;
    params: GtmMeshTransformationParams;
}

export interface UnparsedTransformationResponse {
    created: string;
    modified: string;
    deleted: string;
}

export interface GtmMeshTransformationResponse {
    created: ObjectIdWithVersion[];
    modified: ObjectIdWithVersion[];
    deleted: ObjectIdWithVersion[];
}

export interface GtmGooseContext {
    host: string;
    apiToken: string;
    orgId: string;
    workspaceId: string;
}
