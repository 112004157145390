import { getConfig } from '@local/app-config';
import { ContentArea } from '@local/content-area';
import { BreadcrumbsProvider } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useGetUserDetailsQuery } from '@local/login';
import { getCurrentUserDetails } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { Messages } from '@local/messages/dist/Messages';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import type { NavDrawerItem } from '@local/web-design-system/dist/components/NavDrawer';
import type { ColorVariant } from '@local/web-design-system/dist/components/types';
import { darkTheme, theme } from '@local/web-design-system/dist/styles/theme';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import classnames from 'classnames';
import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { HubSelector } from 'src/components/contentScaffolding/HubSelector';
import { TAGLINE, VERSION_LABEL } from 'src/constants';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { selectIsSyncingProject } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';

import logo from '../../assets/logo.svg';
import { LogoTitle } from '../../assets/logoTitle';
import { GtmBreadcrumbs } from '../GtmBreadcrumbs';
import { useStyles } from './ContentScaffolding.styles';

export const ContentScaffolding = () => {
    const { classes } = useStyles();
    const location = useLocation();
    const applyTrace = useTrace('gtm-content-div');
    const themeContext = useContext(WDS2ThemeContext);
    const isSyncingProject = useAppSelector(selectIsSyncingProject);

    const user = (() => {
        if (getConfig().bentleyIdEnabled) {
            return getCurrentUserDetails();
        }
        return useGetUserDetailsQuery();
    })();

    const navItems: NavDrawerItem[] = [
        {
            key: 'modeler',
            text: 'Modeller',
            location: '/',
            icon: 'evo',
        },
    ];

    const breadcrumbComponent = (
        <>
            <GtmBreadcrumbs />
            <HubSelector />
        </>
    );

    const footerComponent = (
        <div automation-id="gtm-version" className={classes.footer} data-dd-privacy="allow">
            <span style={{ marginRight: '16px', display: 'flex', alignItems: 'center' }}>
                {isSyncingProject ? (
                    <>
                        <CircularProgress sx={{ marginRight: 1, color: '#bdbdbd' }} size="10px" />
                        Syncing Project
                    </>
                ) : (
                    'Project Synced\u00a0'
                )}
            </span>
            {TAGLINE}&nbsp;&nbsp;&nbsp;{VERSION_LABEL}
        </div>
    );

    return (
        <ThemeProvider theme={themeContext.theme === 'dark' ? darkTheme : theme}>
            <BreadcrumbsProvider>
                <ContentArea
                    currentRoute={location.pathname}
                    navItems={navItems}
                    service="geotechnical-modeler"
                    variant={themeContext.theme as ColorVariant}
                    footerComponent={footerComponent}
                    userResult={user}
                    exactMatch={false}
                    logoOnly={<img src={logo} className={classes.appLogo} alt="Modeller" />}
                    logoWithTitle={<LogoTitle />}
                    evoSelector={breadcrumbComponent}
                >
                    <div
                        className={classnames(classes.appContent, classes.webVizContent)}
                        automation-id={applyTrace()}
                    >
                        <Outlet />
                    </div>
                    <Messages />
                </ContentArea>
            </BreadcrumbsProvider>
        </ThemeProvider>
    );
};
