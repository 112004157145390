import type {
    GtmMeshDetectorAction,
    GtmMeshDetectionData,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    setLoadingForObjectDetection,
    setErrorForObjectDetection,
    setIssueDataForObjectDetection,
} from 'src/store/issues/issuesSlice';
import { useAppDispatch } from 'src/store/store';

export function useIssueManager() {
    const dispatch = useAppDispatch();

    return {
        setLoading: (objectId: string, action: GtmMeshDetectorAction, isLoading: boolean) =>
            dispatch(setLoadingForObjectDetection([objectId, action, isLoading])),
        setError: (objectId: string, action: GtmMeshDetectorAction, isError: boolean) =>
            dispatch(setErrorForObjectDetection([objectId, action, isError])),
        setIssueData: (
            objectId: string,
            action: GtmMeshDetectorAction,
            data: GtmMeshDetectionData,
        ) => dispatch(setIssueDataForObjectDetection([objectId, action, data])),
    };
}
