import type { GtmMeshDetectionData } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import type { IssuesMap, IssueInfo } from 'src/store/issues/issuesSlice.types';

const actionCountedInGroups = (action: GtmMeshDetectorAction) =>
    [
        GtmMeshDetectorAction.DetectFins,
        GtmMeshDetectorAction.DetectHoles,
        GtmMeshDetectorAction.DetectNonPartitioningSurfaces,
    ].includes(action);

export function countIssues(action: GtmMeshDetectorAction, issues?: GtmMeshDetectionData) {
    if (!issues || issues.length === 0) return 0;
    if (actionCountedInGroups(action)) return issues.length;
    return issues[0].points.length + issues[0].edges.length + issues[0].triangles.length;
}

export function countAllIssues(issuesMap: IssuesMap) {
    return Object.entries(issuesMap).reduce(
        (acc: number, [action, issueInfo]: [string, IssueInfo]) =>
            acc + countIssues(action as GtmMeshDetectorAction, issueInfo.data),
        0,
    );
}
