import { createSelector } from '@reduxjs/toolkit';

import { defaultAnalyticalModelSettings } from 'src/apiClients/gtmCompute/gtmComputeApi';
import type { GtmAnalyticalModelSettings } from 'src/gtmProject';
import type { RootState } from 'src/store/store';
import type { RemeshSettingsState } from 'src/store/ui/remeshSettings/remeshSettings.types';
import { stringAsFiniteNumber } from 'src/utils/math';

export function remeshSettingsFromAnalyticalModelSettings(
    analyticalModelSettings: GtmAnalyticalModelSettings,
): RemeshSettingsState {
    return {
        patchAngleTolerance: analyticalModelSettings.remeshSettings.patchAngleTolerance.toString(),
        patchAngleToleranceValid: true,
        maxChordalError: analyticalModelSettings.remeshSettings.maxChordalError.toString(),
        maxChordalErrorValid: true,
        targetElementSize: analyticalModelSettings.remeshSettings.targetH.toString(),
        targetElementSizeValid: true,
        shapeQualityWeight: analyticalModelSettings.remeshSettings.shapeQualityWeight.toString(),
        shapeQualityWeightValid: true,
    };
}

export function analyticalModelSettingsFromRemeshSettings(
    existingAnalyticalModelSettings: GtmAnalyticalModelSettings,
    remeshSettings: RemeshSettingsState,
): GtmAnalyticalModelSettings {
    return {
        ...existingAnalyticalModelSettings,
        remeshSettings: {
            patchAngleTolerance: stringAsFiniteNumber(remeshSettings.patchAngleTolerance),
            maxChordalError: stringAsFiniteNumber(remeshSettings.maxChordalError),
            targetH: stringAsFiniteNumber(remeshSettings.targetElementSize),
            shapeQualityWeight: stringAsFiniteNumber(remeshSettings.shapeQualityWeight),
        },
    };
}

export const initialRemeshSettingsState = remeshSettingsFromAnalyticalModelSettings(
    defaultAnalyticalModelSettings,
);

type SelectorTypeString = (state: RootState) => string;
type SelectorTypeBoolean = (state: RootState) => boolean;

const remeshSettingsState = (state: RootState): RemeshSettingsState => state.remeshSettings;

export const selectAllRemeshSettings = remeshSettingsState;

export const selectPatchAngleTolerance: SelectorTypeString = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.patchAngleTolerance,
);

export const selectPatchAngleToleranceValid: SelectorTypeBoolean = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.patchAngleToleranceValid,
);

export const selectMaxChordalError: SelectorTypeString = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.maxChordalError,
);

export const selectMaxChordalErrorValid: SelectorTypeBoolean = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.maxChordalErrorValid,
);

export const selectTargetElementSize: SelectorTypeString = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.targetElementSize,
);

export const selectTargetElementSizeValid: SelectorTypeBoolean = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.targetElementSizeValid,
);

export const selectShapeQualityWeight: SelectorTypeString = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.shapeQualityWeight,
);

export const selectShapeQualityWeightValid: SelectorTypeBoolean = createSelector(
    remeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.shapeQualityWeightValid,
);
