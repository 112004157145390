import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const FolderCloudIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <path d="M9 22.75H7C2.59 22.75 1.25 21.41 1.25 17V7C1.25 2.59 2.59 1.25 7 1.25H8.5C10.25 1.25 10.8 1.82 11.5 2.75L13 4.75C13.33 5.19 13.38 5.25 14 5.25H17C21.41 5.25 22.75 6.59 22.75 11V13C22.75 13.41 22.41 13.75 22 13.75C21.59 13.75 21.25 13.41 21.25 13V11C21.25 7.43 20.58 6.75 17 6.75H14C12.72 6.75 12.3 6.30999 11.8 5.64999L10.3 3.64999C9.77999 2.95999 9.62 2.75 8.5 2.75H7C3.42 2.75 2.75 3.43 2.75 7V17C2.75 20.57 3.42 21.25 7 21.25H9C9.41 21.25 9.75 21.59 9.75 22C9.75 22.41 9.41 22.75 9 22.75Z" />
        <path d="M19.3403 22.81C19.3303 22.81 19.3202 22.81 19.3102 22.81H13.7502C12.0802 22.69 11.2402 21.41 11.2402 20.19C11.2402 19.21 11.7902 18.18 12.8502 17.76C12.6302 16.51 12.9503 15.35 13.7803 14.48C14.8003 13.41 16.4402 12.98 17.8602 13.41C19.1002 13.79 19.9902 14.77 20.3602 16.13C21.4102 16.45 22.2403 17.28 22.5803 18.41C22.9803 19.72 22.6102 21.06 21.6202 21.92C21.0202 22.49 20.2003 22.81 19.3403 22.81ZM13.7902 19.07C13.0302 19.14 12.7502 19.71 12.7502 20.19C12.7502 20.67 13.0302 21.25 13.8102 21.31H19.3203C19.8003 21.35 20.2803 21.13 20.6403 20.81C21.2903 20.24 21.3302 19.43 21.1602 18.85C20.9902 18.27 20.4903 17.62 19.6503 17.51C19.3203 17.47 19.0602 17.22 19.0002 16.89C18.7802 15.54 18.0202 15.04 17.4302 14.85C16.5502 14.58 15.5102 14.86 14.8802 15.52C14.4502 15.97 14.0302 16.78 14.5002 18.07C14.6402 18.46 14.4402 18.89 14.0502 19.03C13.9602 19.06 13.8702 19.07 13.7902 19.07Z" />
    </SvgIcon>
);
