/* eslint no-param-reassign: ["error", { "props": false }] */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
    GtmMeshDetectorAction,
    GtmMeshDetectionData,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';

import type { IssuesObjectMap, IssuesState } from './issuesSlice.types';
import { initialState } from './selectors';

function ensureIssuesMapExistsForObject(state: IssuesState, objectId: string) {
    if (!state.issuesMap[objectId]) {
        state.issuesMap[objectId] = {};
    }
}

function ensureIssuesMapContainsDetectorEntry(
    state: IssuesState,
    objectId: string,
    detectionType: GtmMeshDetectorAction,
) {
    ensureIssuesMapExistsForObject(state, objectId);
    if (!state.issuesMap[objectId][detectionType]) {
        state.issuesMap[objectId][detectionType] = {};
    }
}

export const issuesSlice = createSlice({
    name: 'issues',
    initialState,
    reducers: {
        setLoadingForObjectDetection(
            state: IssuesState,
            {
                payload: [objectId, detectionType, isLoading],
            }: PayloadAction<[string, GtmMeshDetectorAction, boolean]>,
        ) {
            ensureIssuesMapContainsDetectorEntry(state, objectId, detectionType);
            state.issuesMap[objectId]![detectionType]!.isLoading = isLoading;
        },
        setErrorForObjectDetection(
            state: IssuesState,
            {
                payload: [objectId, detectionType, isError],
            }: PayloadAction<[string, GtmMeshDetectorAction, boolean]>,
        ) {
            ensureIssuesMapContainsDetectorEntry(state, objectId, detectionType);
            state.issuesMap[objectId]![detectionType]!.isError = isError;
        },
        setIssueDataForObjectDetection(
            state: IssuesState,
            {
                payload: [objectId, detectionType, data],
            }: PayloadAction<[string, GtmMeshDetectorAction, GtmMeshDetectionData]>,
        ) {
            ensureIssuesMapContainsDetectorEntry(state, objectId, detectionType);
            state.issuesMap[objectId]![detectionType]!.data = data;
        },
        removeIssuesForObject(state: IssuesState, { payload: objectId }: PayloadAction<string>) {
            delete state.issuesMap[objectId];
        },
        clearIssues(state: IssuesState) {
            state.issuesMap = {};
        },
        setIssuesMap(state: IssuesState, { payload }: PayloadAction<IssuesObjectMap>) {
            state.issuesMap = payload;
        },
    },
});

export const {
    setLoadingForObjectDetection,
    setErrorForObjectDetection,
    setIssueDataForObjectDetection,
    removeIssuesForObject,
    clearIssues,
    setIssuesMap,
} = issuesSlice.actions;
