import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import Button from '@mui/material/Button/Button';

import type { GtmMeshDetectSelfIntersectionsParams } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    GtmMeshDetectorAction,
    GtmMeshTransformationAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { getDetectorParams } from 'src/hooks/defects/useDefectsLoadingManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
    TransformationStatus,
    useTransformationManager,
} from 'src/hooks/transformation/useTransformationManager';
import { issueDataForObjectAndAction } from 'src/store/issues/selectors';
import {
    selectCurrentAnalyticalModelSettings,
    selectCurrentModelSelectedObject,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { assert } from 'src/utils/gtmAssert';
import {
    FIX_SELF_INTERSECTIONS_FAILURE_MESSAGE,
    FIX_SELF_INTERSECTIONS_LABEL,
    FIX_SELF_INTERSECTIONS_MESSAGE,
    FIX_SELF_INTERSECTIONS_SUCCESS_MESSAGE,
} from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/TransformationAction.constants';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

const titlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, FIX_SELF_INTERSECTIONS_MESSAGE],
    [TransformationStatus.Uploading, FIX_SELF_INTERSECTIONS_MESSAGE],
    [TransformationStatus.Complete, FIX_SELF_INTERSECTIONS_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, FIX_SELF_INTERSECTIONS_FAILURE_MESSAGE],
]);
const percentagesByStatus = new Map<TransformationStatus, number>([
    [TransformationStatus.Transforming, 0],
    [TransformationStatus.Uploading, 85],
    [TransformationStatus.Complete, 100],
    [TransformationStatus.Failed, 100],
]);

export const FixSelfIntersections = () => {
    const applyTrace = useTrace('fix-self-intersections');
    const { executeTransformation, transformationStatus } = useTransformationManager();
    const selectedObject = useAppSelector(selectCurrentModelSelectedObject);
    assert(
        selectedObject !== undefined,
        'Self-intersection fixer does not know to which object it applies.',
    );
    const analyticalModelSettings = useAppSelector(selectCurrentAnalyticalModelSettings);
    const selfIntersections = useAppSelector(
        issueDataForObjectAndAction(
            selectedObject!.id,
            GtmMeshDetectorAction.DetectSelfIntersections,
        ),
    );
    const { resetVolumesIfObjectIsAggregate } = useVolumesManager();

    const handleTransformation = () => {
        if (!selfIntersections || !selectedObject || !analyticalModelSettings) {
            return;
        }

        const detectorParams = getDetectorParams(
            analyticalModelSettings,
            GtmMeshDetectorAction.DetectSelfIntersections,
        ) as GtmMeshDetectSelfIntersectionsParams;
        const params = {
            tolerance: detectorParams.tolerance,
            assumeNoSelfIntersectionsInParts: false,
        };

        executeTransformation(
            GtmMeshTransformationAction.Intersect,
            ShouldRenderUpdatedObjects.Yes,
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [selectedObject],
            params,
            {
                handleAdditionalSideEffects: () => {
                    resetVolumesIfObjectIsAggregate(selectedObject);
                },
            },
        );
    };

    return (
        <>
            <Button
                automation-id={applyTrace('fix-button')}
                size="small"
                sx={{ width: '100%' }}
                variant="contained"
                onClick={handleTransformation}
            >
                {FIX_SELF_INTERSECTIONS_LABEL}
            </Button>
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationTitles={titlesByStatus}
                transformationPercentages={percentagesByStatus}
            />
        </>
    );
};
