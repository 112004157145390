import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import type { UpdateSnapshot, Vector3, Snapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, ViewClass, LinesMode } from '@local/webviz/dist/xyz';

export interface BoundingBox {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
    zMin: number;
    zMax: number;
}

/**
 * Compares two bounding boxes to see if they are equal
 * @param box1 The first bounding box
 * @param box2 The second bounding box
 * @returns True if the bounding boxes are equal, false otherwise
 * @example areBoundingBoxesEqual({ xMin: 0, xMax: 1, yMin: 0, yMax: 1, zMin: 0, zMax: 1 }, { xMin: 0, xMax: 1, yMin: 0, yMax: 1, zMin: 0, zMax: 1 }) // true
 */
export function areBoundingBoxesEqual(box1: BoundingBox, box2: BoundingBox): boolean {
    return (
        box1.xMin === box2.xMin &&
        box1.xMax === box2.xMax &&
        box1.yMin === box2.yMin &&
        box1.yMax === box2.yMax &&
        box1.zMin === box2.zMin &&
        box1.zMax === box2.zMax
    );
}

// TODO: this is a temporary workaround for line and point XYZ objects not supporting positionOffset.
// Just offset the point data instead.
export function applyPositionOffset(vertices: Float32Array, positionOffset: Vector3): Float32Array {
    const offsetVerts = new Float32Array(vertices.length);
    for (let i = 0; i < vertices.length; i += 3) {
        offsetVerts[i] = vertices[i] + positionOffset[0];
        offsetVerts[i + 1] = vertices[i + 1] + positionOffset[1];
        offsetVerts[i + 2] = vertices[i + 2] + positionOffset[2];
    }
    return offsetVerts;
}

export function computeBoundingBoxFromCenter(
    position: Vector3 | Float32Array,
    offset: number,
): BoundingBox {
    return {
        xMin: position[0] - offset,
        xMax: position[0] + offset,
        yMin: position[1] - offset,
        yMax: position[1] + offset,
        zMin: position[2] - offset,
        zMax: position[2] + offset,
    };
}

export function computeBoundingBoxVertices(box: BoundingBox): Float32Array {
    const { xMin, xMax, yMin, yMax, zMin, zMax } = box;
    // prettier-ignore
    return Float32Array.from([
        xMin, yMin, zMin,   // 0
        xMax, yMin, zMin,   // 1
        xMax, yMax, zMin,   // 2
        xMin, yMax, zMin,   // 3
        xMin, yMin, zMax,   // 4
        xMax, yMin, zMax,   // 5
        xMax, yMax, zMax,   // 6
        xMin, yMax, zMax,   // 7
    ]);
}

export function getGlobalBoundingBox(boundingBoxes: BoundingBox[]): BoundingBox | undefined {
    if (boundingBoxes.length === 0) {
        return undefined;
    }

    const initialBoundingBox: BoundingBox = {
        xMin: Infinity,
        xMax: -Infinity,
        yMin: Infinity,
        yMax: -Infinity,
        zMin: Infinity,
        zMax: -Infinity,
    };

    const globalBoundingBox = boundingBoxes.reduce(
        (acc, bbox) => ({
            xMin: Math.min(acc.xMin, bbox.xMin),
            xMax: Math.max(acc.xMax, bbox.xMax),
            yMin: Math.min(acc.yMin, bbox.yMin),
            yMax: Math.max(acc.yMax, bbox.yMax),
            zMin: Math.min(acc.zMin, bbox.zMin),
            zMax: Math.max(acc.zMax, bbox.zMax),
        }),
        initialBoundingBox,
    );

    return globalBoundingBox;
}

export function getBoundingBoxSnapshot(box: BoundingBox, label: string): UpdateSnapshot {
    const elementId = `bounding-box-${label}`;
    const viewId = label;

    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Surface,
            vertices: computeBoundingBoxVertices(box),
            // prettier-ignore
            triangles: [
                0, 2, 1, 0, 3, 2,   // xyMin
                4, 5, 6, 4, 6, 7,   // xyMax
                0, 1, 5, 0, 5, 4,   // xzMin
                2, 7, 6, 2, 3, 7,   // xzMax
                3, 4, 7, 3, 0, 4,   // yzMin
                1, 2, 6, 1, 6, 5,   // yzMax
            ],
        },

        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            color: [39, 242, 96],
            wireframe: false,
            showFaces: true,
            opacity: 0.2,
        }),
    };
}

export function computeBoundingBoxMaxSideLength(box: BoundingBox): number {
    const xDiff = box.xMax - box.xMin;
    const yDiff = box.yMax - box.yMin;
    const zDiff = box.zMax - box.zMin;
    return Math.max(xDiff, yDiff, zDiff);
}

export function getLinedBoundingBoxSnapshot(
    box: BoundingBox,
    label: string,
    positionOffset?: Vector3,
): UpdateSnapshot {
    const elementId = `lined-bounding-box-${label}`;
    const viewId = label;

    const vertices = positionOffset
        ? applyPositionOffset(computeBoundingBoxVertices(box), positionOffset)
        : computeBoundingBoxVertices(box);

    // The radius of the lines is a fraction of the maximum side length of the bounding box.
    // This prevents the lines from being too thin or too thick when the bounding box is too large
    // or too small respectively.
    const LINE_RADIUS_FACTOR = 0.0015;

    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Lines,
            vertices,
            segments: [0, 1, 1, 2, 2, 3, 3, 0, 0, 4, 4, 5, 5, 6, 6, 7, 7, 4, 1, 5, 2, 6, 3, 7],
        },

        [viewId]: generateEntity(ViewClass.Lines, {
            id: viewId,
            element: elementId,
            color: [59, 169, 239],
            mode: LinesMode.Tubes,
            radius: computeBoundingBoxMaxSideLength(box) * LINE_RADIUS_FACTOR,
        }),
    };
}

/**
 * Gets the bounding box of an xyz plot from a snapshot
 * @param xyzSnapshot The snapshot to get the bounding box from
 * @returns The bounding box of the plot if it exists
 */
export function getPlotBoundingBox(xyzSnapshot: Snapshot): BoundingBox | undefined {
    const { boundingBox } = xyzSnapshot.plot as any; // boundingBox exists on `InternalPlotState`, which is not an exported type.
    if (boundingBox) {
        return {
            xMin: boundingBox.min[0],
            xMax: boundingBox.max[0],
            yMin: boundingBox.min[1],
            yMax: boundingBox.max[1],
            zMin: boundingBox.min[2],
            zMax: boundingBox.max[2],
        };
    }
    return undefined;
}
