import { useState } from 'react';

import {
    defaultAnalyticalModelSettings,
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import type {
    GtmMeshTransformationAction,
    GtmMeshTransformationParams,
    GtmMeshTransformationResponse,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import type { GtmAnalyticalModelSettings, GtmHistoryOperation, GtmModel } from 'src/gtmProject';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { useGooseContext } from 'src/hooks/useGooseContext';
import {
    selectCurrentAnalyticalModelSettings,
    selectCurrentModel,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import type { GeoscienceObject, ObjectIdWithVersion } from 'src/types/core.types';
import { summarizeTransformationActionHistoryOperation } from 'src/utils/history/historySummary';

export enum TransformationStatus {
    Transforming,
    Uploading,
    Complete,
    Failed,
    Cancelled,
}

export enum ShouldRenderUpdatedObjects {
    No = 'No',
    Yes = 'Yes',
}

export enum ShouldRunDetectorsOnUpdatedObjects {
    No = 'No',
    Yes = 'Yes',
}

// return { executeTransformation, transformationStatus }
// executeTransformation - function encapsulating common logic for executing a transformation.
// transformationStatus - stateful variable to track the status of the transformation.
//
// The purpose of this hook is to encapsulate common logic for executing a transformation.
// For all transformations, we want to:
//     1. Make the transformation query (one of `GtmMeshTransformationAction`s)
//     2. Check whether the transformation should be cancelled
//         a. Some transformations should be cancelled to prevent race conditions, e.g. volume
//            computation should be cancelled if the aggregate used as input is outdated.
//         b. Transformations could also be cancelled if the user decides to cancel the operation.
//     3. Update the project state.
//         a. Update modified objects (can handle consistently: find the object in the store and update it)
//         b. Remove deleted objects (can handle consistently: find the object in the store and update it)
//         c. Add created objects (cannot handle consistently: we don't know where to add the object, so leave it up to consumer)
//     4. Update the visualization, i.e. render the objects.
//     5. Handle additional side effects
//         a. Some transformations affect other parts of the project state
//            Consumers can provide a handler to update bits of the store as necessary,
//            meaning that project state changes can be synchronized to file all together.
//     6. Transformations require addition of a history entry.
//     7. Run detectors on the created and modified objects.
//     8. Synchronize the project file.
export function useTransformationManager() {
    const [transformationStatus, setTransformationStatus] = useState<
        TransformationStatus | undefined
    >(undefined);

    const { makeTransformationQuery } = useTransformationQuery(setTransformationStatus);
    const { updateState, updateVisualization, runDetectors, handleSideEffects } =
        useResponseHandlers();
    const { uploadProject } = useProjectUploader(setTransformationStatus);
    const analyticalModelSettings =
        useAppSelector(selectCurrentAnalyticalModelSettings) ?? defaultAnalyticalModelSettings;
    const currentModel = useAppSelector(selectCurrentModel);

    async function executeTransformation(
        transformationAction: GtmMeshTransformationAction,
        renderUpdatedObjects: ShouldRenderUpdatedObjects,
        runDetectorsOnCreatedObjects: ShouldRunDetectorsOnUpdatedObjects,
        objects: ObjectIdWithVersion[],
        params: GtmMeshTransformationParams,
        options?: {
            createdObjectsHandler?: (objects: ObjectIdWithVersion[]) => void;
            handleAdditionalSideEffects?: (
                transformationResponse: GtmMeshTransformationResponse,
            ) => void;
            cancellationPredicate?: (
                transformationResponse: GtmMeshTransformationResponse,
            ) => boolean;
        },
    ) {
        try {
            const response = await makeTransformationQuery(transformationAction, objects, params);

            // Everything inside this block is synchronous and execution won't be paused and
            // interleaved with other async functions.
            // Start of synchronous block
            if (options?.cancellationPredicate?.(response)) {
                setTransformationStatus(TransformationStatus.Cancelled);
                return undefined;
            }

            updateState(response, options?.createdObjectsHandler);
            updateVisualization(response, renderUpdatedObjects);
            handleSideEffects(response, options?.handleAdditionalSideEffects);
            // `runDetectors` is async but we don't await the result. It will immediately
            // return an unfulfilled promise that will be resolved in the background.
            runDetectors(response, runDetectorsOnCreatedObjects, analyticalModelSettings);
            // End of synchronous block

            await uploadProject(
                response,
                summarizeTransformationActionHistoryOperation(
                    transformationAction,
                    params,
                    currentModel as GtmModel,
                    objects as GeoscienceObject[],
                ),
            );
            setTransformationStatus(TransformationStatus.Complete);
            return response;
        } catch (error) {
            setTransformationStatus(TransformationStatus.Failed);
            return await Promise.reject(error);
        } finally {
            setTransformationStatus(undefined);
        }
    }

    return { executeTransformation, transformationStatus };
}

function rejectTransformation() {
    return Promise.reject(new Error('Transformation failed.'));
}

function useTransformationQuery(setTransformationStatus: (status: TransformationStatus) => void) {
    const gooseContext = useGooseContext();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();

    return {
        makeTransformationQuery: async (
            transformationAction: GtmMeshTransformationAction,
            objects: ObjectIdWithVersion[],
            params: GtmMeshTransformationParams,
        ) => {
            setTransformationStatus(TransformationStatus.Transforming);

            const { data, isError } = await GtmMeshTransformationTrigger(
                formGtmMeshTransformationBody(gooseContext!, transformationAction, objects, params),
            ).catch(rejectTransformation);

            return isError || !data ? rejectTransformation() : data;
        },
    };
}

function useResponseHandlers() {
    const { runAllDetectorsOnObject, renderObject, clearVisualizationAndIssuesForObject } =
        useConglomerateActionManager();
    const { findObjectAndSetVersion, findObjectAndDelete } = useObjectManager();

    return {
        updateState: (
            transformationResponse: GtmMeshTransformationResponse,
            createdObjectsHandler?: (objects: ObjectIdWithVersion[]) => void,
        ) => {
            if (createdObjectsHandler) createdObjectsHandler(transformationResponse.created);
            transformationResponse.modified.forEach((obj) => {
                findObjectAndSetVersion(obj.id, obj.version);
            });
            transformationResponse.deleted.forEach((obj) => findObjectAndDelete(obj.id));
        },
        updateVisualization: (
            transformationResponse: GtmMeshTransformationResponse,
            renderUpdatedObjects: ShouldRenderUpdatedObjects,
        ) => {
            transformationResponse.deleted.forEach((obj) =>
                clearVisualizationAndIssuesForObject(obj),
            );

            if (renderUpdatedObjects === ShouldRenderUpdatedObjects.No) return;
            transformationResponse.created.forEach((obj) => renderObject(obj));
            transformationResponse.modified.forEach((obj) => renderObject(obj));
        },
        runDetectors: (
            transformationResponse: GtmMeshTransformationResponse,
            runDetectorsOnCreatedObjects: ShouldRunDetectorsOnUpdatedObjects,
            analyticalModelSettings: GtmAnalyticalModelSettings,
        ) => {
            if (runDetectorsOnCreatedObjects === ShouldRunDetectorsOnUpdatedObjects.Yes) {
                transformationResponse.created.forEach((obj) => {
                    runAllDetectorsOnObject(obj, analyticalModelSettings);
                });
                transformationResponse.modified.forEach((obj) => {
                    runAllDetectorsOnObject(obj, analyticalModelSettings);
                });
            }
        },
        handleSideEffects: (
            transformationResponse: GtmMeshTransformationResponse,
            sideEffectsHandler?: (transformationResponse: GtmMeshTransformationResponse) => void,
        ) => {
            if (sideEffectsHandler) sideEffectsHandler(transformationResponse);
        },
    };
}

function useProjectUploader(setTransformationStatus: (status: TransformationStatus) => void) {
    const { syncProject } = useProjectSynchronizer();

    return {
        uploadProject: async (
            transformationResponse: GtmMeshTransformationResponse,
            gtmHistoryOperation: GtmHistoryOperation,
        ) => {
            setTransformationStatus(TransformationStatus.Uploading);
            return syncProject(gtmHistoryOperation)
                .then(() => transformationResponse)
                .catch(() => Promise.reject(new Error('Sync failed.')));
        },
    };
}
