import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';

export interface ActionDialogProps {
    open: boolean;
    title: string;
    message?: string;
    icon?: JSX.Element;
    actions?: ReactNode;
    progressBar?: JSX.Element;
}

/**
 * A functional component that renders a dialog with a title, message, optional icon, and optional actions
 * according to our design.
 *
 * @param {Readonly<ActionDialogProps>} props - The properties for the ActionDialog component.
 * @param {boolean} props.open - Determines whether the dialog is open or closed.
 * @param {string} props.title - The title text displayed in the dialog.
 * @param {string} props.message - The message text displayed in the dialog.
 * @param {JSX.Element} [props.icon] - An optional icon element displayed alongside the title and message.
 * @param {ReactNode} [props.actions] - Optional action elements displayed at the bottom of the dialog.
 *
 * @returns {JSX.Element} The rendered ActionDialog component.
 */
export function ActionDialog({
    open,
    title,
    message,
    icon,
    actions,
    progressBar,
}: Readonly<ActionDialogProps>) {
    return (
        <Dialog open={open}>
            <DialogContent sx={{ display: 'flex' }} dividers>
                {icon}
                <Box ml={2}>
                    <Stack direction="column" width={300}>
                        <Typography variant="body1" color="textPrimary">
                            {title}
                        </Typography>
                        {message && (
                            <Typography variant="body2" color="textSecondary">
                                {message}
                            </Typography>
                        )}
                        {progressBar}
                    </Stack>
                </Box>
            </DialogContent>
            {actions && (
                <DialogActions sx={{ justifyContent: 'space-between' }}>{actions}</DialogActions>
            )}
        </Dialog>
    );
}
