import { getCurrentEvoInstance } from '@local/login';
import { getCombinedToken as getAccessToken } from '@local/login/dist/store/sessionStorageHelpers/accessTokenHelper/accessTokenHelper';
import { useParams } from 'react-router-dom';

import type { GtmGooseContext } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { assert } from 'src/utils/gtmAssert';

export function useGooseContext(): GtmGooseContext | null {
    const accessToken = getAccessToken()?.access_token;
    const { org, hub } = getCurrentEvoInstance();
    const { workspaceUuid } = useParams();

    if (hub?.url && accessToken && org?.id && workspaceUuid) {
        // Remove the protocol from the url since the backend doesn't expect it
        const hostUrl = hub!.url?.replace(/(^\w+:|^)\/\//, '');
        return {
            host: hostUrl,
            apiToken: accessToken,
            orgId: org?.id,
            workspaceId: workspaceUuid,
        };
    }

    assert(false, 'Attempting to access invalid evo context.');
    return null;
}
