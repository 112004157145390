import type { ObjectIdWithVersion, GeoscienceObject } from 'src/types/core.types';

export function decorateNewObject(
    newObject: ObjectIdWithVersion,
    name: string,
    schema: string,
    visible: boolean,
): GeoscienceObject {
    return {
        ...newObject,
        name,
        schema,
        visible,
    };
}
