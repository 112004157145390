import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const FillHolesIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <g fill="none">
            <path d="M9 14H6V19L12 21L17.5 19V14H15" stroke="currentColor" strokeWidth="2" />
            <path d="M12 16V7M12 7H8.5V3H15V7H12Z" stroke="currentColor" strokeWidth="2" />
        </g>
    </SvgIcon>
);
