import type { GeoscienceObject } from 'src/types/core.types';

export const DELETE_MODEL_TITLE = 'Deleting Model';
export const getDeleteModelMessage = (modelName: string) =>
    `Are you sure you want to delete "${modelName}" from this project?`;
export const getDeleteProjectMessage = (projectName: string) =>
    `Are you sure you want to delete project "${projectName}"?`;
export const NEW_MODEL_NAME = 'New Model';
export const NEW_PROJECT_NAME = 'New Project';
export const INVALID_NEW_MODEL_NAME_MESSAGE = 'This model already exists';
export const INVALID_NEW_PROJECT_NAME_MESSAGE = 'This project already exists';
export const EMPTY_OBJECTS_MESSAGE =
    'Find and add objects from your workspace or upload them directly';
export const NO_OBJECTS_YET_MESSAGE = 'No objects yet';
export const WORKSPACE_LABEL = 'Workspace';
export const UPLOAD_LABEL = 'Upload';
export const CREATE_ANALYTICAL_BOUNDARY_LABEL = 'Create Analytical Boundary';
export const ADDED_LABEL = 'Added';
export const ADD_LABEL = 'Add';
export const OBJECTS_LABEL = 'Objects';
export const MODEL_LABEL = 'Model';
export const MODELS_LABEL = 'Models';
export const CANCEL_LABEL = 'Cancel';
export const DELETE_LABEL = 'Delete';
export const RENAME_LABEL = 'Rename';
export const PROJECT_INITIALIZING_ERROR = 'Error initializing project';
export const getProjectCreationErrorMessage = (projectName: string) =>
    `Error creating project with name ${projectName}.`;
export const PROJECT_RENAME_ERROR = 'Error renaming project';
export const AT_LEAST_ONE_PROJECT_REQUIRED_ERROR = 'A workspace must contain at least one project';
export const DELETE_PROJECT_TITLE = 'Deleting Project';
export const getAddModelDescription = (name: string) => `Add model "${name}"`;
export const getDeleteModelDescription = (name: string) => `Delete model "${name}"`;
export const getRenameModelDescription = (prevName: string, newName: string) =>
    `Rename model "${prevName}" to "${newName}"`;
export const getAddObjectsToModelDescription = (objects: GeoscienceObject[], modelName: string) =>
    `Add ${objects.length} object${objects.length === 1 ? '' : 's'} to model "${modelName}"`;
export const getAddObjectToModelDescription = (object: GeoscienceObject, modelName: string) =>
    `Add object "${object.name}" to model "${modelName}"`;
export const getRemoveObjectFromModelDescription = (object: GeoscienceObject, modelName: string) =>
    `Remove object "${object.name}" from model "${modelName}"`;
export const getObjectVisibilityDescription = (
    object: GeoscienceObject,
    modelName: string,
    shouldShow: boolean,
) => `${shouldShow ? 'Show' : 'Hide'} object "${object.name}" in model "${modelName}"`;
