import { getCombinedToken as getAccessToken } from '@local/login/dist/store/sessionStorageHelpers/accessTokenHelper/accessTokenHelper';
import { getCurrentEvoInstance } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { useParams } from 'react-router-dom';

import type { GtmGooseContext } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import type { GtmProject } from 'src/gtmProject';
import { isGtmAnalyticalModel } from 'src/gtmProject';
import { store } from 'src/store/store';
import { PROJECT_EXTENSION } from 'src/strings';
import type { ObjectIdWithVersion } from 'src/types/core.types';

export function buildGooseContext(): GtmGooseContext | null {
    const accessToken = getAccessToken()?.access_token;
    const { org, hub } = getCurrentEvoInstance();
    const params = useParams();
    const { workspaceUuid } = params;

    if (hub?.url && accessToken && org?.id && workspaceUuid) {
        // Remove the protocol from the url since the backend doesn't expect it
        const hostUrl = hub?.url?.replace(/(^\w+:|^)\/\//, '');
        return {
            host: hostUrl,
            apiToken: accessToken,
            orgId: org?.id,
            workspaceId: workspaceUuid,
        };
    }
    return null;
}

export function geoscienceObjectByVersion(objectId: string, versionId: string) {
    if (objectId && versionId) {
        return [{ id: objectId, version: versionId }];
    }
    return [];
}

export function createFileFromProject(project: GtmProject) {
    return new File(
        [
            new Blob([JSON.stringify(project)], {
                type: 'application/json',
            }),
        ],
        `${project.name}.${PROJECT_EXTENSION}`,
    );
}

export function isObjectOutdatedOrMissing(object: ObjectIdWithVersion): boolean {
    return isObjectOutdatedOrMissingFromStore(store, object);
}

export function isObjectOutdatedOrMissingFromStore(
    sourceStore: any, // Not using `store` name to avoid clash with the global store.
    object: ObjectIdWithVersion,
): boolean {
    // Get project from the store directly to get the latest values.
    const currentProject: GtmProject = sourceStore.getState().project.current.project;

    // Issue detection and transformations can only be done in analytical models.
    const analyticalModels = currentProject.models.filter((model) => isGtmAnalyticalModel(model));

    let objectExists = false;
    let objectIsOld = false;
    for (const model of analyticalModels) {
        const newestObject =
            model.aggregateGeometry.id === object.id
                ? model.aggregateGeometry
                : model.objects.find((obj) => obj.id === object.id);

        if (newestObject) {
            objectExists = true;
            // Versions are UNIX timestamps, so we can compare them directly.
            objectIsOld = Number(newestObject.version) > Number(object.version);
            break;
        }
    }

    return !objectExists || objectIsOld;
}
