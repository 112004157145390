import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import LocateIcon from '@mui/icons-material/LocationSearchingOutlined';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect } from 'react';

import type { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { toHighlightViewId } from 'src/hooks/defects/uid';
import { useDefectsVisualizationManager } from 'src/hooks/defects/useDefectsVisualizationManager';
import { issueDataForObjectAndAction } from 'src/store/issues/selectors';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjectIds } from 'src/store/visualization/selectors';
import { detectorActionToDefectString } from 'src/utils/typeTransformations';

export function IssueListItem({
    artifactName,
    artifactIndex,
    action,
}: {
    artifactName: string;
    artifactIndex: number;
    action: GtmMeshDetectorAction;
}) {
    const selectedObjectId = useAppSelector(selectedSceneObjectIds)[0];
    const defectData = useAppSelector(issueDataForObjectAndAction(selectedObjectId, action));
    const { removeHighlightsForObject, renderHighlights } = useDefectsVisualizationManager();
    const { getZoomToViewTool } = useBaseXyz();

    // TODO: Handle multiple selected objects
    const defectName = detectorActionToDefectString(action);
    const viewId = toHighlightViewId(selectedObjectId, defectName, artifactIndex.toString());
    const handleSearchClick = () => {
        removeHighlightsForObject(selectedObjectId, action);
        renderHighlights(selectedObjectId, defectData!, action, artifactIndex);
        getZoomToViewTool().zoomToView(viewId);
    };
    useEffect(() => removeHighlightsForObject(selectedObjectId, action), [defectData]);

    return (
        <ListItem disableGutters disablePadding divider>
            <ListItemButton sx={(theme) => ({ padding: theme.spacing(0, 1) })}>
                <ListItemIcon sx={{ minWidth: '36px' }}>
                    <IconButton edge="end" onClick={handleSearchClick} size="small">
                        <LocateIcon />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary={artifactName} />
            </ListItemButton>
        </ListItem>
    );
}
