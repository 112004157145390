import type { Color as XyzColor } from '@local/webviz/dist/types/xyz';

import type { RequestObject } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    GtmMeshDetectorAction,
    GtmMeshTransformationAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import type { GtmBounds } from 'src/gtmProject/Project.types';
import * as strings from 'src/strings';
import type { GooseBoundingBox, ObjectColor, ObjectIdWithVersion } from 'src/types/core.types';
import type { BoundingBox } from 'src/utils/xyzUtils';

import { clamp } from './math';

export function boundingBoxToGtmBounds(boundingBox: BoundingBox): GtmBounds {
    return {
        minPoint: { x: boundingBox.xMin, y: boundingBox.yMin, z: boundingBox.zMin },
        maxPoint: { x: boundingBox.xMax, y: boundingBox.yMax, z: boundingBox.zMax },
        rotationAngle: 0,
    };
}

export function gooseToGtmBoundingBox(boundingBox: GooseBoundingBox): BoundingBox {
    return {
        xMin: boundingBox.min_x,
        xMax: boundingBox.max_x,
        yMin: boundingBox.min_y,
        yMax: boundingBox.max_y,
        zMin: boundingBox.min_z,
        zMax: boundingBox.max_z,
    };
}

export function gtmColorToRGBArray(color: ObjectColor): XyzColor {
    return [color.r, color.g, color.b];
}

// Assumes alpha is 255 (fully opaque)
export function rgbArrayToGtmColor(rgbArray: number[] | XyzColor): ObjectColor {
    return { r: rgbArray[0], g: rgbArray[1], b: rgbArray[2], a: 255 };
}

// Returns a floating-point value on [0.0, 1.0]
export function alphaToOpacity(alpha: number): number {
    return clamp(alpha / 255.0, 0.0, 1.0);
}

// returns an integer value on [0, 255]
export function opacityToAlpha(opacity: number): number {
    return clamp(Math.round(opacity * 255.0), 0, 255);
}

// [r, g, b] of 0-255 -> #rrggbb
// Assumes the input is a valid RGB color 0-255
export function xyzColorToHtmlColor(color: XyzColor): string {
    const [r, g, b] = color;
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
        .toString(16)
        .padStart(2, '0')}`;
}

// #rrggbb or #rgb -> [r, g, b] of 0-255
// Assumes the input is a valid hex color string
export function htmlColorToXyzColor(color: string): XyzColor {
    const hex = color.replace('#', '');
    const hexSixChar =
        hex.length === 3 ? `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}` : hex;
    if (hexSixChar.length !== 6) throw new Error(`Invalid color string: ${color}`);

    const r = parseInt(hexSixChar.slice(0, 2), 16);
    const g = parseInt(hexSixChar.slice(2, 4), 16);
    const b = parseInt(hexSixChar.slice(4, 6), 16);
    return [r, g, b];
}

export function detectorActionToDefectString(action: GtmMeshDetectorAction) {
    switch (action) {
        case GtmMeshDetectorAction.DetectDegenerateTris:
            return strings.DEGENERATE_TRIANGLE;
        case GtmMeshDetectorAction.DetectDuplicatePoints:
            return strings.DUPLICATE_POINT;
        case GtmMeshDetectorAction.DetectDuplicateTris:
            return strings.DUPLICATE_TRIANGLE;
        case GtmMeshDetectorAction.DetectFins:
            return strings.FIN;
        case GtmMeshDetectorAction.DetectHoles:
            return strings.HOLE;
        case GtmMeshDetectorAction.DetectSelfIntersections:
            return strings.SELF_INTERSECTION;
        case GtmMeshDetectorAction.DetectNonManifoldEdges:
            return strings.NON_MANIFOLD_EDGE;
        case GtmMeshDetectorAction.DetectNonManifoldVertices:
            return strings.NON_MANIFOLD_VERTEX;
        case GtmMeshDetectorAction.DetectInconsistentlyOrientedTris:
            return strings.INCONSISTENT_TRIANGLE;
        case GtmMeshDetectorAction.DetectNonPartitioningSurfaces:
            return strings.REDUNDANT_SURFACE;
        default:
            return strings.UNKNOWN_DEFECT_TYPE;
    }
}

export function detectorActionToTransformationAction(action: GtmMeshDetectorAction) {
    switch (action) {
        case GtmMeshDetectorAction.DetectHoles:
            return GtmMeshTransformationAction.FillHoles;
        case GtmMeshDetectorAction.DetectDegenerateTris:
            return GtmMeshTransformationAction.RemoveDegenerateTris;
        case GtmMeshDetectorAction.DetectSelfIntersections:
            return GtmMeshTransformationAction.Intersect;
        default:
            console.error(`Matching transformation not defined for detector action: ${action}`);
            return null;
    }
}

export function toRequestObject(object: ObjectIdWithVersion): RequestObject {
    // Leave the brand undefined - it's used to enforce type conversion,
    // but we don't want to send it through our queries.
    return { id: object.id, version: object.version } as RequestObject;
}
