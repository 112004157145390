/* eslint no-param-reassign: ["error", { "props": false }] */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { PublishPanelState } from 'src/store/ui/publishPanel/publishPanel.types';
import { initialPublishPanelState } from 'src/store/ui/publishPanel/selectors';

export const publishPanelSlice = createSlice({
    name: 'publishPanel',
    initialState: initialPublishPanelState,
    reducers: {
        setShouldShowPublishPanel(
            state: PublishPanelState,
            { payload: shouldShow }: PayloadAction<boolean>,
        ) {
            state.shouldShowPublishPanel = shouldShow;
        },
        setModelExportName(
            state: PublishPanelState,
            { payload: exportName }: PayloadAction<string>,
        ) {
            state.modelExportName = exportName;
        },
    },
});

export const { setShouldShowPublishPanel, setModelExportName } = publishPanelSlice.actions;
