import type { SurfaceElementState } from '@local/webviz/dist/types/xyz';

import type { LineGeometry, SurfaceGeometry } from './types';

export const NUM_TRIANGLE_VERTICES = 3;
export const NUM_COORDINATES_3D = 3;

type SurfaceVerticesOnly = Omit<
    SurfaceElementState,
    'triangles' | 'trianglesUrl' | 'verticesUrl' | 'id' | 'closed' | '__class__' | 'positionOffset'
>;

type SurfaceVerticesAndTriangles = Omit<
    SurfaceElementState,
    'trianglesUrl' | 'verticesUrl' | 'id' | 'closed' | '__class__' | 'positionOffset'
>;

export function extractUsedGeometryFromIndices(
    vertices: Float32Array,
    inputIndices: Int32Array | number[],
): { vertices: Float32Array; indices: Int32Array } {
    const uniqueIndices = new Set<number>(inputIndices);
    const usedVertices = new Float32Array(uniqueIndices.size * NUM_COORDINATES_3D);
    {
        let i = 0;
        for (const index of uniqueIndices) {
            usedVertices.set(
                vertices.slice(index * NUM_COORDINATES_3D, (index + 1) * NUM_COORDINATES_3D),
                i * NUM_COORDINATES_3D,
            );
            i += 1;
        }
    }

    const idUpdateTable = new Map<number, number>();
    let compressedIndex = 0;
    for (const index of uniqueIndices) {
        idUpdateTable.set(index, compressedIndex);
        compressedIndex += 1;
    }

    const indices = new Int32Array(inputIndices.length);
    for (let i = 0; i < inputIndices.length; i += 1) {
        indices[i] = idUpdateTable.get(inputIndices[i]) as number;
    }

    return { vertices: usedVertices, indices };
}

export function generateSurfaceWithDefectedTris(
    surfaceElement: SurfaceVerticesAndTriangles,
    defectedTriangles: number[],
): SurfaceGeometry {
    const allTriangleVerticesIndex = new Int32Array(
        defectedTriangles.length * NUM_TRIANGLE_VERTICES,
    );
    let currentIndex = 0;
    for (const triangleIndex of defectedTriangles) {
        const triangleVerticesIndex = surfaceElement.triangles.slice(
            triangleIndex * NUM_TRIANGLE_VERTICES,
            (triangleIndex + 1) * NUM_TRIANGLE_VERTICES,
        );
        allTriangleVerticesIndex.set(triangleVerticesIndex, currentIndex);
        currentIndex += NUM_TRIANGLE_VERTICES;
    }

    const { vertices, indices } = extractUsedGeometryFromIndices(
        surfaceElement.vertices,
        allTriangleVerticesIndex,
    );
    return { vertices, triangles: indices };
}

function getVertexWithPointId(pointId: number, surface: SurfaceVerticesOnly) {
    return [
        surface.vertices[pointId * NUM_COORDINATES_3D],
        surface.vertices[pointId * NUM_COORDINATES_3D + 1],
        surface.vertices[pointId * NUM_COORDINATES_3D + 2],
    ];
}

/**
 * From a list of point indices, generate a flattened list of vertex coordinates.
 * @param points - point indices
 * @param surface - the surface with the vertex coordinates
 * @returns Float32Array: a flattened list of all the vertex coordinates and the indices of the points.
 */
export function generateVerticesFromPointIds(
    points: number[],
    surface: SurfaceVerticesOnly,
): Float32Array {
    const holePointsLength = points.length;
    const pointVertices = new Float32Array(holePointsLength * NUM_TRIANGLE_VERTICES);
    points.forEach((pointIndex, flatVerticesIndex) => {
        const point = getVertexWithPointId(pointIndex, surface);
        pointVertices.set(point, flatVerticesIndex * NUM_TRIANGLE_VERTICES);
    });
    return pointVertices;
}

export function numberOfBoundaryVertices(edges: [number, number][]) {
    return edges.length > 0 ? edges.length + 1 : 0;
}

/**
 * Given a list of contiguous edges representing a boundary, generate a flattened list of contiguous
 * vertices.
 * Example:
 * - Given `edges` [[10, 11], [11, 12], [12, 10]]
 * - Return `vertices` [v10x, v10y, v10z, v11x, v11y, v11z, v12x, v12y, v12z, v10x, v10y, v10z]
 * Precondition: `edges` is a list of contiguous edges, i.e. the last point of edge i is the first
 * point of edge i+1.
 *
 * @param vertices - the vertices buffer to fill
 * @param edges - the list of contiguous edges
 * @param surface - the surface with the vertex coordinates
 * @param offset - the offset in the vertices buffer to start filling. Do not pass the number of previous vertices, pass vertices * NUM_COORDINATES_3D
 */
export function fillBoundaryVertices(
    vertices: Float32Array,
    edges: [number, number][],
    surface: SurfaceVerticesOnly,
    offset: number,
) {
    edges.forEach((edge, edgeIndex) => {
        const firstEdgeVertex = getVertexWithPointId(edge[0], surface);
        const vertexIndex = edgeIndex * NUM_COORDINATES_3D + offset;
        vertices.set(firstEdgeVertex, vertexIndex);
    });
    const lastEdgeChainVertex = getVertexWithPointId(edges[edges.length - 1][1], surface);
    vertices.set(lastEdgeChainVertex, edges.length * NUM_COORDINATES_3D + offset);
}

/**
 * Given a list of contiguous edges representing a boundary, generate a flattened list of contiguous
 * vertices.
 * Example:
 * - Given `edges` [[10, 11], [11, 12], [12, 10]]
 * - Return `vertices` [v10x, v10y, v10z, v11x, v11y, v11z, v12x, v12y, v12z, v10x, v10y, v10z]
 * Precondition: `edges` is a list of contiguous edges, i.e. the last point of edge i is the first
 * point of edge i+1.
 */
export function generateBoundaryVertices(edges: [number, number][], surface: SurfaceVerticesOnly) {
    const verticesCount = numberOfBoundaryVertices(edges);
    const vertices = new Float32Array(verticesCount * NUM_COORDINATES_3D);
    fillBoundaryVertices(vertices, edges, surface, 0);
    return vertices;
}

export function generateEdgeVertices(edge: [number, number], surface: SurfaceVerticesOnly) {
    const p0 = getVertexWithPointId(edge[0], surface);
    const p1 = getVertexWithPointId(edge[1], surface);
    return new Float32Array([...p0, ...p1]);
}

/**
 * Fills an index buffer with a line's sequence of vertices. Assumes two vertices per line segment and
 * vertices are shared between sequential segments. This is a 'hack' because there is no line strip.
 *
 * Example:
 * - Given `offset` 6 and `numberOfVerticesThisSequence` 4
 * - Return `indices` [x, x, x, x, x, x, 3, 4, 4, 5, 5, 6, 6, 7]
 *   Where x's denote values already in the buffer.
 *
 * @param indices the index buffer to fill
 * @param offset where to start writing
 * @param numberOfEdgesThisSequence the number of edges in this line sequence.
 *
 * @returns the number of indices added to the buffer.
 */
export function fillLineIndexSequence(
    indices: Int32Array,
    offset: number,
    firstVertexIndex: number,
    numberOfEdgesThisSequence: number,
) {
    indices.set([firstVertexIndex], offset);
    const last = offset + numberOfEdgesThisSequence * 2 - 1;
    let currentIndex = firstVertexIndex + 1;
    for (let i = offset + 1; i < last; i += 2) {
        indices.set([currentIndex, currentIndex], i);
        currentIndex += 1;
    }
    indices.set([currentIndex], last);
    return numberOfEdgesThisSequence * 2;
}

/**
 * Create an index buffer for 'max' number of edges in a line sequence.
 * This is a 'hack' because there is no line strip.
 *
 * @param max The number of edges in the line sequence.
 * @returns An index buffer of the form [0, 1, 1, 2, 2, 3, ..., max-1, max]
 */
export function generateLineIndexSequence(max: number): Int32Array {
    const sequence = new Int32Array(max * 2);
    fillLineIndexSequence(sequence, 0, 0, max);
    return sequence;
}

/**
 * Generate display data for edges that are part of a surface.
 */
export function generateEdgeDisplayData(
    edges: [number, number][],
    surface: SurfaceVerticesOnly,
): { vertices: Float32Array; segments: Int32Array } {
    const { vertices, indices } = extractUsedGeometryFromIndices(surface.vertices, edges.flat());
    return { vertices, segments: indices };
}

const getUndirectedEdge = (vertexId1: number, vertexId2: number): [number, number] =>
    vertexId1 < vertexId2 ? [vertexId1, vertexId2] : [vertexId2, vertexId1];

function uniqueEdgeSetStrings(edges: [number, number][]): Set<string> {
    // Note that we use strings because Arrays are objects and compare by identity, not value.
    const uniqueEdgeSet = new Set<string>(
        edges.map((edge) => JSON.stringify(getUndirectedEdge(edge[0], edge[1]))),
    );
    return uniqueEdgeSet;
}

export function uniqueEdges(edges: [number, number][]): [number, number][] {
    const uniqueEdgeSet = uniqueEdgeSetStrings(edges);
    const uniqued = Array.from(uniqueEdgeSet).map(
        (edgeStr) => JSON.parse(edgeStr) as [number, number],
    );
    return uniqued;
}

/**
 * Generate display data for all unique edges of a surface patch except excluded edges.
 */
export function generateSurfaceEdgeDisplayData(
    surface: SurfaceVerticesAndTriangles,
    patchTriangles: number[],
    excludedEdges: [number, number][],
) {
    const nakedEdgeSet = uniqueEdgeSetStrings(excludedEdges);

    const triangles = surface.triangles as Int32Array;
    const allEdges = new Set<string>();
    for (const triangleIndex of patchTriangles) {
        const offset = triangleIndex * NUM_TRIANGLE_VERTICES;
        const pointId1 = triangles[offset];
        const pointId2 = triangles[offset + 1];
        const pointId3 = triangles[offset + 2];
        const edges = [
            getUndirectedEdge(pointId1, pointId2),
            getUndirectedEdge(pointId1, pointId3),
            getUndirectedEdge(pointId2, pointId3),
        ];
        for (const edge of edges) {
            const edgeStr = JSON.stringify(edge);
            if (!nakedEdgeSet.has(edgeStr)) {
                allEdges.add(edgeStr);
            }
        }
    }

    const uniqued = Array.from(allEdges).map((edgeStr) => JSON.parse(edgeStr) as [number, number]);

    return generateEdgeDisplayData(uniqued, surface);
}

export function generateTriangleUsingLineSeg(
    surfaceElement: SurfaceVerticesAndTriangles,
    triangleIndex: number,
): LineGeometry {
    const trianglePointIds = surfaceElement.triangles.slice(
        triangleIndex * NUM_TRIANGLE_VERTICES,
        (triangleIndex + 1) * NUM_TRIANGLE_VERTICES,
    );
    const v0 = getVertexWithPointId(trianglePointIds[0], surfaceElement);
    const v1 = getVertexWithPointId(trianglePointIds[1], surfaceElement);
    const v2 = getVertexWithPointId(trianglePointIds[2], surfaceElement);
    const lineVertices = new Float32Array([...v0, ...v1, ...v2]);
    const lineSegments = new Int32Array([0, 1, 1, 2, 2, 0]);
    return { vertices: lineVertices, segments: lineSegments };
}
